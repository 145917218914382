<template>
    <div class="ma-0" v-loading="loading" id="by_state_and_region">
        <el-table
                :data="dataList"
                :fit="true"
                height="300"
                class="my-2 width-100-p data-table"
                stripe
                scrollbar-always-on
            >
            <el-table-column
                :label="convertText('state')"
                :prop="'state'"
                :width="$vuetify.display.width <= 1750 ? 250 : 137"
                sortable
                />
                <el-table-column
                :label="convertText('country')"
                :prop="'country'"
                :width="$vuetify.display.width <= 1750 ? 250 : 137"
                sortable
                />
                <el-table-column
                :label="convertText('client')"
                :prop="'client'"
                :width="$vuetify.display.width <= 1750 ? 250 : 137"
                sortable
                />
                <el-table-column
                :label="convertText('afec')"
                :prop="'afec'"
                :formatter="rounding"
                :width="$vuetify.display.width <= 1750 ? 250 : 137"
                sortable
                >
                <template #default="scope">
                    {{
                    scope.row.afec == null
                        ? 0
                        : Number(scope.row.afec).toLocaleString()
                    }}
                </template>
                </el-table-column>

                <el-table-column
                :label="convertText('mpg')"
                :prop="'mpg'"
                :formatter="rounding"
                :width="$vuetify.display.width <= 1750 ? 250 : 137"
                sortable
                >
                <template #default="scope">
                    {{
                    scope.row.mpg == null
                        ? 0
                        : Number(scope.row.mpg).toLocaleString()
                    }}
                </template>
                </el-table-column>

                <el-table-column
                :label="convertText('no_existing_chargers')"
                :prop="'no_existing_chargers'"
                :width="$vuetify.display.width <= 1750 ? 250 : 180"
                sortable
                />
                <el-table-column
                :label="convertText('no_utilized_chargers')"
                :prop="'no_utilized_chargers'"
                :width="$vuetify.display.width <= 1750 ? 250 : 180"
                sortable
                />
                <el-table-column
                :label="convertText('energy_delivered')"
                :prop="'energy_delivered'"
                :formatter="rounding"
                :width="$vuetify.display.width <= 1750 ? 250 : 180"
                sortable
                >
                <template #default="scope">
                    {{
                    scope.row.energy_delivered == null
                        ? 0
                        : Number(scope.row.energy_delivered).toLocaleString()
                    }}
                </template>
                </el-table-column>

                <el-table-column
                :label="convertText('energy_consumed')"
                :prop="'energy_consumed'"
                :formatter="rounding"
                :width="$vuetify.display.width <= 1750 ? 250 : 180"
                sortable
                >
                <template #default="scope">
                    {{
                    scope.row.energy_consumed == null
                        ? 0
                        : Number(scope.row.energy_consumed).toLocaleString()
                    }}
                </template>
                </el-table-column>

                <el-table-column
                :label="convertText('emission_factor_electricity')"
                :prop="'emission_factor_electricity'"
                :formatter="rounding"
                :width="$vuetify.display.width <= 1750 ? 250 : 200"
                sortable
                >
                <template #default="scope">
                    {{
                    scope.row.emission_factor_electricity == null
                        ? 0
                        : Number(scope.row.emission_factor_electricity).toLocaleString()
                    }}
                </template>
                </el-table-column>

                <el-table-column
                :label="convertText('project_tco2')"
                :prop="'project_tco2'"
                :formatter="rounding"
                :width="$vuetify.display.width <= 1750 ? 250 : 137"
                sortable
                >
                <template #default="scope">
                    {{
                    scope.row.project_tco2 == null
                        ? 0
                        : Number(scope.row.project_tco2).toLocaleString()
                    }}
                </template>
                </el-table-column>

                <el-table-column
                :label="convertText('baseline_tco2')"
                :prop="'baseline_tco2'"
                :formatter="rounding"
                :width="$vuetify.display.width <= 1750 ? 250 : 137"
                sortable
                >
                <template #default="scope">
                    {{
                    scope.row.baseline_tco2 == null
                        ? 0
                        : Number(scope.row.baseline_tco2).toLocaleString()
                    }}
                </template>
                </el-table-column>

                <el-table-column
                :label="convertText('abatement_tco2')"
                :prop="'abatement_tco2'"
                :formatter="rounding"
                :width="$vuetify.display.width <= 1750 ? 250 : 140"
                sortable
                >
                <template #default="scope">
                    {{
                    scope.row.abatement_tco2 == null
                        ? 0
                        : Number(scope.row.abatement_tco2).toLocaleString()
                    }}
                </template>
                </el-table-column>

                <el-table-column
                :label="convertText('abatement_per_mwh')"
                :prop="'abatement_per_mwh'"
                :formatter="rounding"
                :width="$vuetify.display.width <= 1750 ? 250 : 170"
                sortable
                >
                <template #default="scope">
                    {{
                    scope.row.abatement_per_mwh == null
                        ? 0
                        : Number(scope.row.abatement_per_mwh).toLocaleString()
                    }}
                </template>
                </el-table-column>


            </el-table>

            <!-- page count and pagination -->
            <el-row class="row-bg" justify="space-between">
                <el-col
                :span="5"
                class="d-flex justify-content-start align-items-center"
                >
                <el-select
                    v-model="perPageCount"
                    placeholder="Select"
                    size="small"
                    style="width: 70px"
                >
                    <el-option label="10" :value="'10'" />
                    <el-option label="25" :value="'25'" />
                    <el-option label="50" :value="'50'" />
                    <el-option label="100" :value="'100'" />
                </el-select>
                </el-col>
                <el-col
                :span="8"
                class="d-flex align-items-center justify-content-center"
                >
                <el-pagination
                    small
                    background
                    layout="prev, pager, next"
                    :total="total_items"
                    v-model:page-size="page_size"
                    v-model:current-page="current_page"
                    @current-change="changePage"
                    class="my-4"
                />
                </el-col>
                <el-col :span="5" class="d-flex justify-content-end align-items-center">
                <span class="text-white">
                    {{ (current_page - 1) * perPageCount + 1 }}-{{
                    current_page * perPageCount > total_items
                        ? total_items
                        : current_page * perPageCount
                    }}
                    of {{ total_items }}
                </span>
                </el-col>
            </el-row>
            <!-- page count and pagination -->


    </div>
</template>

<script>
import userService from "@/services/user.service";
import { snakeToTitleCase } from "./tools";
import { mapMutations } from "vuex";

export default {


    data(){
        return{
            request: {
                start_date: null,
                end_date: null,
                search: null,
                report_type : "by_state_and_region",

                current_page: null,
                perPageCount: null,
            },
            dataList:[],

            // for page count and pagination
            perPageCount: 50,
            current_page: 1,
            page_size: 50,
            total_items: 100,

            // for page count and pagination
            
        }
    },

    watch: {

        // for page count
        perPageCount: function (newVal) {
            this.current_page = 1;
            this.page_size = parseInt(newVal);
            this.tableAction({
                index: this.page_size,
                data: null,
                kind: "perPageChange",
            });
            },
        // for page count
        },

    methods: {
        ...mapMutations('auditorReport', ['set_StateRegion']),

        // for page count and pagination
        changePage(page) {
            this.tableAction ({
                index: page,
                data: null,
                kind: "changePage",
            });
        },

        handleReload() {
            this.request.current_page = this.current_page;
            this.request.perPageCount = this.perPageCount;
            this.getTableData(this.request);
        },


        tableAction(event) {
            if (event.kind == "changePage") {
                this.current_page = event.index;
                this.handleReload();
            } else if (event.kind == "perPageChange") {
                this.current_page = 1;
                this.perPageCount = event.index;
                this.handleReload();
            }
            },
        // for page count and pagination

        convertText(t){
            return snakeToTitleCase(t);
        },

        // rounding table display numbers
        rounding(row, column) {
            const value = row[column.property];
            const valueToNumber = parseFloat(value);

            return !isNaN(valueToNumber) ? parseFloat(valueToNumber.toFixed(4)) : 0; // set digits of data on output table
        },

        async getTableData(request) {
            this.dataList=[];
            this.request.start_date = request.startDate;
            this.request.end_date = request.endDate;
            this.request.search = request.search;
            console.log("received request", request);
            
            request.report_type = this.request.report_type;
            await userService.DashboardAuditorReports(this.request).then(
                (res) => {
                    if (res.data != null) {
                            // console.log(res);
                            // this.test = res.data;
                            this.dataList = res.data.data;
                            this.total_items = res.data.total_record;
                            this.set_StateRegion(this.dataList);
                        } else {
                            console.log("empty response data, check error");
                        }
                }
            ).finally(()=>{
                console.log("request done");
            });
        },
    },
    mounted(){
        this.getTableData(this.request);
    }
}
</script>

<style scoped>

.ma-0 >>> .el-table__row>td{
    border-color: #234b49; 
}

.ma-0 >>> .el-table th.is-leaf {
    border-color: #234b49; 
    border-bottom: none;
}

.ma-0 >>> .el-table__inner-wrapper::before {
    background-color: #234b49;
}

</style>