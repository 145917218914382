<!-- eslint-disable vue/multi-word-component-names -->

<template>
    <Breadcrumbs main="Estimator" :path="breadcrumbPath" />
    <div class="container-fluid p-0">

        <userInputs v-if="!isSubmitted" :key="resetInput" @resetForm="handleInputReset" @submitForm="handleSubmitted" />  

        <eOutput v-if="isSubmitted" @resetForm="handleReset" @exportPdf="handleExport" />

        <genPdf v-if="isSubmitted" ref="childPdf" />


    </div>


</template>

<script>
import userInputs from "./userInputs.vue";

import eOutput from "./estimaOutput.vue";

import genPdf from "./genPdf.vue";

import Breadcrumbs from "@/components/bread_crumbs.vue";



// handel resizeobsever error overlay start
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
// handel resizeobsever error overlay end

export default {
    props: {},
    components: {
        // Filter,
        // inputForm,
        Breadcrumbs,
        userInputs,
        eOutput,
        genPdf,

    },
    computed: {
        stateList() {
        return this.states;
        },
    },
    data(){
        return{

            resetInput : 0,

            isSubmitted: false, // control component switch

            states: [],
            filter: {
                state: "",
                startDate: null,
                endDate: null,
                customer_id: null,
            },
            breadcrumbPath: [
                // {
                // title: "General",
                // disabled: true,
                // href: "/home",
                // },
                // {
                // title: "Estimator",
                // disabled: false,
                // href: "/dashboard-estimator",
                // active: true,
                // },
            ],
        }
    },

    methods: {

        handleSubmitted() {
            this.isSubmitted = true;

            console.log("form submitted");
            
        },

        handleReset() {
            this.isSubmitted = false;
        },

        handleExport() {
            console.log("index receive pdf ");
            this.$refs.childPdf.generateReport();
            
        },

        handleInputReset() {
            this.resetInput += 1;
        }

    },
 
}
</script>

<style scoped>
span {
    color: white;
}
label{
    color: white;
}
</style>
