<template>
    <div>
        <Vue3Html2pdf :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="false"
    :paginate-elements-by-height="4804"
    filename="Estimator-Output"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a2"
    pdf-orientation="landscape"
    pdf-content-width=100
    @beforeDownload="beforeDownload($event)"
    ref="html2Pdf">
            <template #pdf-content>
                <Breadcrumbs main="Estimator" title="default" />
                <div class="bg-me-primary border-color-light width-100-p">
                    <estimaOutput @generateReport="generateReport" />
                </div>
            </template>
        </Vue3Html2pdf>
    </div>

</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import estimaOutput from './estimaOutput.vue';

export default {
    components: {
        Vue3Html2pdf,
        estimaOutput,
    },
    methods: {
        async beforeDownload({html2pdf, options, pdfContent }){
            
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                
                const totalPages = pdf.internal.getNumberOfPages();

                pdf.deletePage(totalPages);
                
            }).save();
        },

        generateReport() {

            try {
              
                this.$refs.html2Pdf.generatePdf();

            } catch(error) {
                console.error(error);
            }
            
        },
    },
}



</script>

<style scoped>
span{
    color: white;
}
</style>

