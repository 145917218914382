<template>

    <div class="container-fluid p-0 " id="convert-to-pdf">
        <div  class="row widget-grid">

            <el-row class="m-0 width-100-p mt-3">
                <el-col
                :span="24">
                    <!-- Input Summary table -->
                    <div class="estimaInputTable">

                        <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                            <h5 class="text-white mt-3 ml-5">Input summary</h5>

                            <el-table :data="inputTableData" stripe scrollbar-always-on style="width: 95%" class="mt-3 mb-3 ml-5 data-table">

                                <el-table-column prop="location" label="Location" :width="$vuetify.display.width <= 1750 ? 250 : 230"  />

                                <el-table-column prop="charging_type" label="Charging type" :width="$vuetify.display.width <= 1750 ? 250 : 240" />

                                <el-table-column prop="usage" label="Annual usage (MWh)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 290 : 260">

                                <template #default="scope">
                                    {{
                                    scope.row.usage == null
                                        ? ""
                                        : Number(scope.row.usage).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="annual_growth_in_usage" label="Annual growth in usage (%)" :width="$vuetify.display.width <= 1750 ? 290 : 260" >
                                <template #default="scope">
                                    {{
                                    scope.row.annual_growth_in_usage == null
                                        ? ""
                                        : Number(scope.row.annual_growth_in_usage).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="ei" label="Grid emission intensity (tCO2-e/MWh)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 290 : 260" >
                                <template #default="scope">
                                    {{
                                    scope.row.ei == null
                                        ? ""
                                        : Number(scope.row.ei).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="annual_emission_intensity_improvement" label="Annual emission intensity improvement (%)" :width="$vuetify.display.width <= 1750 ? 290 : 280" >
                                <template #default="scope">
                                    {{
                                    scope.row.annual_emission_intensity_improvement == null
                                        ? ""
                                        : Number(scope.row.annual_emission_intensity_improvement).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                            </el-table>

                        </div>

                    </div>
                    <!-- Input Summary table END -->

                </el-col>
            </el-row>

            <!-- <div class="html2pdf__page-break"/> -->

            <el-row class="m-0 width-100-p mt-3">
                <el-col :span="24">
                    <!-- chart -->
                    <div class="estimaChart width-100-p">

                        <div class="m-0 width-100-p mt-3">
                            <estimatorCharts />
                        </div>

                    </div>
                    <!-- chart END-->
                </el-col>
            </el-row>
  

            <!-- <div class="html2pdf__page-break"/> -->

            <el-row class="m-0 width-100-p mt-3">
                <el-col :span="24">

                    <!-- Totals table -->
                    <div v-if="ac_and_dc" class="estimaTotalTable">
                        <!-- show table when charging type ac and dc -->

                        <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                            <h5 class="text-white mt-3 ml-5">Totals</h5>

                            <el-table :data="combinedData" stripe scrollbar-always-on  style="width: 95%" class="mt-3 mb-3 ml-5 data-table">
                                <el-table-column prop="location" label="Location" :width="$vuetify.display.width <= 1750 ? 250 : 100" sortable />
                                <el-table-column prop="year" label="Year" :width="$vuetify.display.width <= 1750 ? 250 : 100" sortable />
                                <el-table-column prop="abatement" label="AC Abatement(tCO2-e)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 155" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.abatement == null
                                        ? ""
                                        : Number(scope.row.abatement).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="project_emission" label="AC Project Emission (tCO2-e)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 190" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.project_emission == null
                                        ? ""
                                        : Number(scope.row.project_emission).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="baseline_emission" label="AC Baseline Emission (tCO2-e)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 190" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.baseline_emission == null
                                        ? ""
                                        : Number(scope.row.baseline_emission).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="abatement_per_mwh" label="AC Abatement per MWh" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 170" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.abatement_per_mwh == null
                                        ? ""
                                        : Number(scope.row.abatement_per_mwh).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="dc_abatement" label="DC Abatement(tCO2-e)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 170" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.dc_abatement == null
                                        ? ""
                                        : Number(scope.row.dc_abatement).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="dc_project_emission" label="DC Project Emission (tCO2-e)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 190" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.dc_project_emission == null
                                        ? ""
                                        : Number(scope.row.dc_project_emission).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="dc_baseline_emission" label="DC Baseline Emission (tCO2-e)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 190" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.dc_baseline_emission == null
                                        ? ""
                                        : Number(scope.row.dc_baseline_emission).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="dc_abatement_per_mwh" label="DC Abatement per MWh" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 170" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.dc_abatement_per_mwh == null
                                        ? ""
                                        : Number(scope.row.dc_abatement_per_mwh).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                            </el-table>

                        </div>

                    </div>
                    <!-- Totals  table END -->

                    <!-- Totals table -->
                    <div v-if="!ac_and_dc" class="estimaTotalTable">
                        <!-- show table when charging type is ac or dc only -->
                    <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                        <h5 class="text-white mt-3 ml-5">Totals</h5>

                        <el-table :data="combinedData" stripe scrollbar-always-on  style="width: 95%" class="mt-3 mb-3 ml-5 data-table">
                            <el-table-column prop="location" label="Location" :width="$vuetify.display.width <= 1750 ? 137 : 130" sortable />
                            <el-table-column prop="year" label="Year" :width="$vuetify.display.width <= 1750 ? 137 : 130" sortable />
                            <el-table-column prop="abatement" label="Abatement(tCO2-e)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 320" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.abatement == null
                                        ? ""
                                        : Number(scope.row.abatement).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                            <el-table-column prop="project_emission" label="Project Emission (tCO2-e)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 320" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.project_emission == null
                                        ? ""
                                        : Number(scope.row.project_emission).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                            <el-table-column prop="baseline_emission" label="Baseline Emission (tCO2-e)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 320" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.baseline_emission == null
                                        ? ""
                                        : Number(scope.row.baseline_emission).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                            <el-table-column prop="abatement_per_mwh" label="Abatement per MWh" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 250 : 320" sortable >
                                <template #default="scope">
                                    {{
                                    scope.row.abatement_per_mwh == null
                                        ? ""
                                        : Number(scope.row.abatement_per_mwh).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                        </el-table>

                    </div>

                    </div>
                    <!-- Totals  table END -->


                </el-col>
            </el-row>

            <div class="html2pdf__page-break"/>

                <!-- two button  -->
                <div id="two-buttons-pdf" v-if="showButtons">
                    <div>

                    <el-row class="m-0 width-100-p mt-3">
                        <el-col class="d-flex align-item-center" :span="$vuetify.display.width >= 1618 ? 3 : ($vuetify.display.width >= 681 ? 6 : ($vuetify.display.width >= 393 ? 4 : 24))">
                            <el-button @click="exportPdf"
                                color="#ddf0ac"
                                class="height-40 rounded-3"
                            >
                                <svg class="stroke-icon width-20 height-20">
                                <use
                                    href="@/assets/svg/icon-sprite.svg#estimator_pdf"
                                ></use>
                                </svg>
                                <span class="me-primary pl-3"> Export to PDF </span>
                            </el-button>
                        </el-col>

                        <el-col class="d-flex align-item-center" :span="$vuetify.display.width >= 1618 ? 2 : ($vuetify.display.width >= 681 ? 3 : ($vuetify.display.width >= 393 ? 2 : 24))">
                            <el-button @click="resetForm"
                                color="#0D3937"
                                class="height-40 rounded-3 border-color-light"
                                >
                                <svg class="stroke-icon width-20 height-20">
                                    <use
                                        href="@/assets/svg/icon-sprite.svg#estimator_reset_btn"
                                        ></use>
                                </svg>
                                    <span class="text-white pl-3"> Reset </span>
                            </el-button> 
                        </el-col>
                    </el-row>

                    </div>

                </div>
                 <!-- two button  -->

        </div>
    </div>

</template>

<script>

import { mapState } from "vuex";
import estimatorCharts from "./estimatorCharts.vue";


export default{
    data(){
        return {
            showButtons: true, //control display button or not
        }
    },
    computed: {

        // data from store for total tables
        ...mapState('estimator', {
            estimatorData: state => state.estimatorData
        }),

        // ...mapState('estimator', {
        //     inputsData: state => state.inputFormData
        // }),

        ...mapState('estimator', {
            ac_and_dc: state => state.ac_and_dc
        }),

        combinedData() {
        // when only ac or dc is selected
        if (!this.estimatorData.ac || !this.estimatorData.dc) {
            return this.estimatorData;
        }
        return this.estimatorData.ac_dc;
        // Combine ac and dc data into one array
        // return [
        //     ...(this.estimatorData.ac || []).map(item => ({ ...item, type: 'ac' })),
        //     ...(this.estimatorData.dc || []).map(item => ({ ...item, type: 'dc' }))
        // ];
        },

        inputTableData() {
            // when only ac or dc is selected
        if (!this.estimatorData.ac || !this.estimatorData.dc) {
            return this.estimatorData.filter(item=>item.year===0);
        }
        // Combine ac and dc data into one array
        return [
            ...(this.estimatorData.ac || []).filter(item => item.year === 0).map(item => ({ ...item, type: 'ac' })),
            ...(this.estimatorData.dc || []).filter(item => item.year === 0).map(item => ({ ...item, type: 'dc' }))
        ];
        },
        


    },

    components:{
        estimatorCharts,
 
    },

    methods: {

        rounding(row, column) {
            return parseFloat(row[column.property].toFixed(4)); // set digits of data on output table
        },
   
        resetForm() {
            this.$emit('resetForm');
            },
        
        exportPdf() {
            this.$emit('exportPdf');
        },

    },

}
</script>

<style scoped>

.estimaInputTable >>> .el-table__row>td{
    border-color: #234b49; 
}

.estimaTotalTable >>> .el-table__row>td{
    border-color: #234b49; 
}
.estimaInputTable >>> .el-table th.is-leaf {
    border-color: #234b49;   
}

.estimaTotalTable >>> .el-table th.is-leaf {
    border-color: #234b49; 
    border-bottom: none;
}
.estimaInputTable >>> .el-table__inner-wrapper::before {
    background-color: #234b49;
}

.estimaTotalTable >>> .el-table__inner-wrapper::before {
    background-color: #234b49;
}





</style>