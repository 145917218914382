const state = {
    estimatorData : null,
    inputFormData : null,
    ac_and_dc: false,
  
};

const mutations = {

    // data from api response, used in estimaOutput and estimatorCharts
    set_estimatorData(state,data) {
        state.estimatorData = data;
        // console.log(data); 
    },


    set_inputFormData(state,data) {
        state.inputFormData = data;
    },

    // control switch display table on estimaOutput
    set_ac_and_dc(state,{showAC,showDC}){
        state.ac_and_dc = showAC && showDC;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
}





