import axios from "./axios";

import {
    API_BASE_URL,
} from '../../env';


class UserService {
    getURL() {
        return API_BASE_URL + "/";
    }
    //#region Dashboard
    DashboardAllData(request) {
        let body = request == null ? {} : request;
        let response = axios.post("api/dashboard-all-data", body);
        return response;
    }

    DashboardHomeData(request) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            customer_id: request.customer_id,
            state: request.state
        };
        let response = axios.post("api/highlights", body);
        return response;
    }

    DashboardSessionsData(request) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            group_by: request.group_by,
            customer_id: request.customer_id,
            charger_name: request.charger_name,
            state: request.state
        };
        let response = axios.post("api/dashboard-session-data", body);
        return response;
    }

    GetAllChargingSite(request, page, page_size = undefined) {
        let body = {
            customer_id: request.customer_id,
            search: request.search,
            state: request.state,
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/all-charging-sites?page=" + params, body);
        return response;
    }

    // DashboardEstimator start
    DashboardEstimator(request) {

        if (!request) {
            console.error("Invalid request object:", request);
            return;
        }
        // console.log("Request body:", JSON.stringify(request));
        return axios.post("api/estimator/", request); 
    }

    DashboardEstimatorFilter() {
        console.log("Getting filter data...");
        return axios.get("api/filterbylocation/"); 
    }

    // DashboardEstimator end

    // Dashboard auditor-reports start

            // request without pagination
            // DashboardAuditorReports(request) {
            //     if (!request) {
            //         console.error("Invalid request object:", request);
            //         return;
            //     }

            //     let response = axios.post("api/auditor-reports", request);
            //     console.log("service received Request:", JSON.stringify(request));
            //     return response;
            // }

    // request with pagination
    DashboardAuditorReports(request) {
        if (!request) {
            console.error("Invalid request object:", request);
            return;
        }

        let page = request.current_page;
        let page_size = request.perPageCount;
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/auditor-reports?page=" + params, request);

        // console.log("service received Request:", JSON.stringify(params));
        // console.log("service received Request:", JSON.stringify(request));
        return response;
    }

    DashboardAuditorReportsTest() {

        return axios.get("api/auditor-reports"); 
    }

    // Dashboard auditor-reports end


    DashboardSessionGroupBy(request) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            kind: request.kind,
            group_by: request.group_by,
            customer_id: request.customer_id,
            charger_name: request.charger_name,
            state: request.state,
            search: request.search,
        };
        let response = axios.post("api/dashboard-session-group-by", body);
        return response;
    }


    // DashboardCharger get xlsx file
    DashboardChargersXlsx() {

        let response = axios.post("api/export-chargerschedule-excel",{},{responseType: 'blob'});
        return response;

    }

    DashboardChargersData(request) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            customer_id: request.customer_id,
            state: request.state,
        };
        let response = axios.post("api/dashboard-chargers-data", body);
        return response;
    }

    ChargersDistributions(request) {
        let body = {
            customer_id: request.customer_id,
            state: request.state,
        };
        let response = axios.post("api/charger-distributions", body);
        return response;
    }

    GetAllCharger(request, page, page_size = undefined) {
        let body = {
            customer_id: request.customer_id,
            search: request.search,
            state: request.state,
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/all-chargers?page=" + params, body);
        return response;
    }

    GetChargerSelection(request) {
        let body = {
            customer_id: request.customer_id,
            search: request.search
        };
        let response = axios.post("api/charger-list-selection", body);
        return response;
    }

    GetAllPort(request, page, page_size = undefined) {
        let body = {
            customer_id: request.customer_id,
            search: request.search,
            state: request.state,
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/all-ports?page=" + params, body);
        return response;
    }

    GetChargerList(request, page, page_size = undefined) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            customer_id: request.customer_id,
            search: request.search,
            state: request.state,
            charging_site_id: request.charging_site_id,
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/charger-list?page=" + params, body);
        return response;
    }

    GetTopPerformingSites(request, page, page_size = undefined) {
        let body = {
            customer_id: request.customer_id,
            state: request.state,
            start_date: request.start_date,
            end_date: request.end_date,
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/top-performing-sites?page=" + params, body);
        return response;
    }

    GetSessionList(request, page, page_size = undefined) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            customer_id: request.customer_id,
            charger_name: request.charger_name,
            search: request.search,
            state: request.state,
        };
        if (page_size != 0) {
            let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
            let response = axios.post("api/session-list?page=" + params, body);
            return response;

        } else {
            let response = axios.post("api/session-list?bypass_pagination=true", body);
            return response;

        }
    }

    GetPendingSessionList(request, page, page_size = undefined) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            customer_id: request.customer_id,
            charger_name: request.charger_name,
            search: request.search,
            kind: request.kind,
            state: request.state
        };

        if (page_size != 0) {
            let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
            let response = axios.post("api/session-log-list?page=" + params, body);
            return response;

        } else {
            let response = axios.post("api/session-log-list?bypass_pagination=true", body);
            return response;

        }
    }

    GetAbatementHistory(request) {
        let body = {
            customer_id: request.customer_id,
            start_date: request.start_date,
            end_date: request.end_date,
            state: request.state,
        };
        let response = axios.post("api/abatement-history", body);
        return response;
    }



    // eslint-disable-next-line no-unused-vars
    GetLiveAbatementList(request, page, page_size = undefined) {
        let body = {
            customer_id: request.customer_id,
            start_date: request.start_date,
            end_date: request.end_date,
            search: request.search,
            state: request.state,
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/get_live_abt?page=" + params, body);
        return response;
    }


    GetCompareDailyEmissions(request) {
        let body = {
            customer_id: request.customer_id,
            start_date: request.start_date,
            end_date: request.end_date,
        };
        // let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/compare-daily-emissions", body);
        return response;
    }



    // eslint-disable-next-line no-unused-vars
    GetAllInOne(request, page, page_size = undefined) {
        let body = {
            customer_id: request.customer_id,
            start_date: request.start_date,
            end_date: request.end_date,
            search: request.search,
        };

        console.log("all in one body:", JSON.stringify(body));
        console.log("all in one request:", JSON.stringify(request));
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/all-in-one?page=" + params, body);
        return response;
    }

    // eslint-disable-next-line no-unused-vars
    GetInvalidSessionList(request, page, page_size = undefined) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            search: request.search,
            customer_id: request.customer_id,
        };
        let response = axios.post("api/dashboard-session-count", body);
        return response;
    }

    GetSessionTimeOfDayList(request) {
        let body = {
            start_date: request.start_date,
            end_date: request.end_date,
            by: request.by,
            customer_id: request.customer_id,
            group_by_item: request.group_by_item,
            count: request.count,
        };
        // console.log(body)
        let response = axios.post("api/dashboard-session-Time-of-day-count", body);
        return response;
    }

    GetOperationsGrowthList(request) {
        let body = {
            by: request.by,
            customer_id: request.customer_id,
            count: request.count,
        };
        let response = axios.post("api/operation-growth-trends", body);
        return response;
    }

    GetInfrastructureGrowthList(request) {
        let body = {
            by: request.by,
            customer_id: request.customer_id,
            count: request.count,
        };
        let response = axios.post("api/infrastructure-growth-trends", body);
        return response;
    }

    //#endregion

    //#region Charger
    setChargers(request) {
        let body = {
            by_panel: true,
            content: request
        };
        let response = axios.post("api/upload-excel-json", body);
        return response;
    }
    setSimpleChargerUpload(request) {
        let body = request;
        let response = axios.post("api/simple-upload-charger-file", body);
        return response;
    }
    //#endregion

    //#region Session
    setSessions(request) {
        let body = {
            by_panel: true,
            content: request
        };
        let response = axios.post("api/upload-session-log-json", body);
        return response;
    }
    setSimpleSessionUpload(request) {
        let body = request;
        let response = axios.post("api/simple-upload-session-file", body);
        return response;
    }

    uploadSessionFile(request) {
        let body = request;
        let response = axios.post("api/upload_file", body);
        return response;
    }

    getSummerySessionLogByError(request, page, page_size = undefined) {
        let body = request;
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/summery-session-log-by-error-list?page=" + params, body);
        return response;
    }

    sessionLogRemoveBy(request) {
        let body = request;
        let response = axios.post("api/session-log-remove-by", body);
        return response;
    }

    AbatementCalculate(request) {
        let body = {
            customer_id: request.customer_id
        };
        let response = axios.post("api/abatement-calculator", body);
        return response;
    }
    //#endregion

    //#region Support
    getCustomerSupport(request, filter, page, page_size = undefined) {
        let body = {};
        if (request != null)
            body = {
                id: request
            };
        else {
            body = {
                from_date: filter.fromDate,
                to_date: filter.toDate,
                status_row: filter.statusRow,
                search: filter.search,
            };
        }
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/customer-supports?page=" + params, body);
        return response;
    }

    SetSupport(request) {
        let body = request;
        let response = axios.post("api/create-support", body);
        return response;
    }

    SetSupportDetail(request) {
        let body = request;
        let response = axios.post("api/create-support-detail", body);
        return response;
    }

    getAdminSupport(request, filter, page, page_size = undefined) {
        let body = {};
        if (request != null)
            body = {
                id: request
            };
        else {
            body = {
                from_date: filter.fromDate,
                to_date: filter.toDate,
                status_row: filter.statusRow,
                search: filter.search,
            };
        }
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/supports?page=" + params, body);
        return response;
    }
    //#endregion

    //#region User
    // eslint-disable-next-line no-unused-vars
    getUsers(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/users?page=" + params, body);
        return response;
    }

    SetUser(request) {
        let body = request;
        let url = (request.id != 0 ? 'api/update-user' : 'api/create-user');
        let response = axios.post(url, body);
        return response;
    }

    deleteUser(request) {
        let body = {
            user_id: request
        };
        let response = axios.post("api/delete-user", body);
        return response;
    }

    //#endregion

    //#region Customer
    // eslint-disable-next-line no-unused-vars
    getCustomers(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/customers?page=" + params, body);
        return response;
    }

    SetCustomer(request) {
        let body = request;
        let url = 'api/save-customer';
        let response = axios.post(url, body);
        return response;
    }

    deleteCustomer(request) {
        let body = {
            customer_id: request
        };
        let response = axios.post("api/delete-customer", body);
        return response;
    }


    deleteCustomerAllData(request) {
        let body = {
            customer_id: request
        };
        let response = axios.post("api/delete-customer-all-data", body);
        return response;
    }

    //#endregion

    //#region ChargingSite
    getChargingSites(request, search, page, page_size = undefined, site_id = null) {
        let body = {
            search: search
        };
        if (request != null)
            body = {
                site_id: request.site_id,
                customer_id: request.customer_id
            };

        if (site_id != null) {
            body = {
                search: search,
                site_id: site_id
            };
        }
        //let response = axios.post("api/session-log-list?bypass_pagination=true", body);
        if (page_size != 0) {
            let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
            let response = axios.post("api/charging-site-list?page=" + params, body);
            return response;

        } else {
            let response = axios.post("api/charging-site-list?bypass_pagination=true", body);
            return response;

        }

    }

    updateChargingSite(request) {
        let body = request;
        let response = axios.post("api/charging-site-update", body);
        return response;
    }

    updateCharger(request) {
        let body = request;
        let response = axios.post("api/charger-update", body);
        return response;
    }

    updatePort(request) {
        let body = request;
        let response = axios.post("api/port-update", body);
        return response;
    }

    deleteChargingSite(request) {
        let body = {
            charging_site_id: request
        };
        let response = axios.post("api/delete-charging-sites", body);
        return response;
    }
    deleteCharger(request) {
        let body = {
            charger_id: request
        };
        console.log("body: ", body)
        let response = axios.post("api/delete-chargers", body);
        return response;
    }
    deleteChargerPort(request) {
        let body = {
            charger_port_id: request
        };
        console.log("body: ", body)
        let response = axios.post("api/delete-ports", body);
        return response;
    }
    //#endregion

    //#region Admin Session
    // eslint-disable-next-line no-unused-vars
    getSessions(request, page, page_size = undefined) {
        let body = request;
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/session-list-view?page=" + params, body);
        return response;
    }
    //#endregion

    //#region Baseline Params
    // eslint-disable-next-line no-unused-vars
    getBaselineParams(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/baseline-params?page=" + params, body);
        return response;
    }

    SetBaselineParam(request) {
        let body = request;
        let url = 'api/save-baseline-param';
        let response = axios.post(url, body);
        return response;
    }

    deleteBaselineParam(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-baseline-param", body);
        return response;
    }

    //#endregion

    //#region SiteBaseline Params
    // eslint-disable-next-line no-unused-vars
    getSiteBaselineParams(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/site-baseline-params?page=" + params, body);
        return response;
    }

    SetSiteBaselineParam(request) {
        let body = request;
        let url = 'api/save-site-baseline-param';
        let response = axios.post(url, body);
        return response;
    }

    deleteSiteBaselineParam(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-site-baseline-param", body);
        return response;
    }

    //#endregion

    //#region Participants
    getParticipants(page, search, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/participants?page=" + params, body);
        return response;
    }

    SetParticipant(request) {
        let body = request;
        let url = 'api/save-participant';
        let response = axios.post(url, body);
        return response;
    }

    deleteParticipant(request) {
        let body = {
            duid: request
        };
        let response = axios.post("api/delete-participant", body);
        return response;
    }

    //#endregion

    //#region History Log
    // eslint-disable-next-line no-unused-vars
    getHistoryLogs(page, filter, page_size = undefined) {
        let body = filter
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/history-logs?page=" + params, body);
        return response;
    }

    //#endregion

    //#region Fleet
    setFleet(request) {
        let body = {
            by_panel: true,
            content: request
        };
        let response = axios.post("api/upload-vehicle", body);
        return response;
    }
    //#endregion

    //#region On Site Renewable Params
    // eslint-disable-next-line no-unused-vars
    getOnSiteRenewable(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/on-site-renewable?page=" + params, body);
        return response;
    }

    SetOnSiteRenewable(request) {
        let body = request;
        let url = 'api/save-on-site-renewable';
        let response = axios.post(url, body);
        return response;
    }

    deleteOnSiteRenewable(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-on-site-renewable", body);
        return response;
    }

    //#endregion

    //#region MonitoringPeriods
    // eslint-disable-next-line no-unused-vars
    getMonitoringPeriods(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/monitoring-periods?page=" + params, body);
        return response;
    }

    SetMonitoringPeriod(request) {
        let body = request;
        let url = 'api/save-monitoring-periods';
        let response = axios.post(url, body);
        return response;
    }

    DeleteMonitoringPeriod(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-monitoring-periods", body);
        return response;
    }

    //#endregion

    //#region Battery Intensities
    // eslint-disable-next-line no-unused-vars
    getBatteryIntensities(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/battery-intensities?page=" + params, body);
        return response;
    }

    SetBatteryIntensities(request) {
        let body = request;
        let url = 'api/save-battery-intensity';
        let response = axios.post(url, body);
        return response;
    }

    DeleteBatteryIntensities(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-battery-intensity", body);
        return response;
    }

    //#endregion

    //#region Vehicles
    // eslint-disable-next-line no-unused-vars
    getVehicles(request, search, page, page_size = undefined) {
        let body = {
            search: search
        };
        let params = (page == undefined ? 1 : page) + (page_size != undefined ? '&page_size=' + page_size : '');
        let response = axios.post("api/vehicles?page=" + params, body);
        return response;
    }

    SetVehicles(request) {
        let body = request;
        let url = 'api/save-vehicle';
        let response = axios.post(url, body);
        return response;
    }

    DeleteVehicles(request) {
        let body = {
            id: request
        };
        let response = axios.post("api/delete-vehicle", body);
        return response;
    }

    //#endregion





}

export default new UserService();