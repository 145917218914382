<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-dialog
    v-model="show"
    width="800"
    :fullscreen="$vuetify.display.width <= 800 ? true : false"
    class="public-style"
    destroy-on-close
    align-center
    @close="close"
  >
    <!-- <span>This is a message</span> -->
    <!-- <el-row :gutter="20"> -->
    <el-form
      :class="$vuetify.display.width <= 800 ? 'frm-class' : ''"
      ref="ruleFormRef"
      :rules="rules"
      :model="charger_data"
      :label-position="`top`"
    >
      <el-space fill style="width: 100%">
        <el-row :gutter="20">
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 12
                : $vuetify.display.width >= 500
                ? 8
                : 24
            "
          >
            <el-form-item
              class="label-style"
              prop="charger_name"
              label="Charger ID: "
            >
              <el-input v-model="charger_data.charger_name" />
            </el-form-item>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 24
            "
          >
            <el-form-item
              class="label-style"
              prop="year_added"
              label="Year Added: "
            >
              <el-input v-model="charger_data.year_added" />
            </el-form-item>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width >= 650
                ? 6
                : $vuetify.display.width >= 500
                ? 8
                : 24
            "
          >
            <el-form-item
              class="label-style"
              prop="charger_type"
              label="Type: "
            >
              <!-- <el-input v-model="charger_data.charger_type" /> -->
              <el-select
                v-model="charger_data.charger_type"
                placeholder="Select"
                class="w-100"
              >
                <el-option
                  v-for="item in chargerTypes"
                  :key="item.id"
                  :label="item.title"
                  :value="item.title"
                >
                  <span style="float: left">{{ item.title }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!-- <el-col
            :span="
              $vuetify.display.width >= 650
                ? 8
                : $vuetify.display.width >= 500
                ? 8
                : 24
            "
          >
            <el-form-item class="label-style" prop="year_added" label="Year Added: ">
              <el-input v-model="charger_data.year_added" />
            </el-form-item>
          </el-col> -->
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="install_date"
              label="Install Date: "
            >
              <!-- <el-input v-model="charger_data.install_date" /> -->
              <el-date-picker
                v-model="charger_data.install_date"
                type="date"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
                class="dtp-customer w-100"
                prefix-icon=""
              />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="operational_date"
              label="Operational Date: "
            >
              <!-- <el-input v-model="charger_data.operational_date" /> -->
              <el-date-picker
                v-model="charger_data.operational_date"
                type="date"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
                class="dtp-customer w-100"
                prefix-icon=""
              />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="decommissioning_date"
              label="Decommissioning Date: "
            >
              <el-date-picker
                v-model="charger_data.decommissioning_date"
                type="date"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DD"
                class="dtp-customer w-100"
                prefix-icon=""
              />
            </el-form-item>
          </el-col>

          <!-- <el-col v-if="$vuetify.display.width >= 650" :span="8">
            <el-form-item class="label-style" label="">
              <el-checkbox
                v-model="charger_data.onsite_battery"
                label="OnSite Battery"
                size="large"
              />
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 6 : 24">
            <el-form-item
              class="label-style"
              prop="charger_capacity_kW"
              label="Capacity KW: "
            >
              <el-input v-model="charger_data.charger_capacity_kW" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 6 : 24">
            <el-form-item
              class="label-style"
              prop="certificate_number"
              label="Certificate Num: "
            >
              <el-input v-model="charger_data.certificate_number" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 6 : 24">
            <el-form-item class="label-style" prop="make" label="Make">
              <el-input v-model="charger_data.make" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 6 : 24">
            <el-form-item class="label-style" prop="model" label="Model: ">
              <el-input v-model="charger_data.model" />
            </el-form-item>
          </el-col>
          <!-- <el-col v-if="$vuetify.display.width >= 650" :span="8">
            <el-form-item class="label-style">
              <el-checkbox
                v-model="charger_data.is_included"
                label="Is Included"
                size="large"
              />
            </el-form-item> -->
          <!-- </el-col> -->
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style" label="">
              <el-checkbox
                class="text-white"
                v-model="charger_data.onsite_renewable"
                label="On-Site Renewable"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style" label="">
              <el-checkbox
                class="text-white"
                v-model="charger_data.onsite_battery"
                label="OnSite Battery"
                size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style">
              <el-checkbox
                class="text-white"
                v-model="charger_data.is_included"
                label="Is Included"
                size="large"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              class="label-style"
              prop="description"
              label="Description: "
            >
              <el-input v-model="charger_data.description" type="textarea" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-space>
    </el-form>
    <!-- </el-row> -->
    <template #footer>
      <span class="dialog-footer">
        <!-- <el-button @click="close">Cancel</el-button>
        <el-button type="primary" @click="confirm"> Confirm </el-button> -->
        <el-button
          size="medium"
          class="me-primary-light"
          @click.stop="confirm"
          color="#0D3937"
        >
          <i class="fa fa-check me-2 me-primary-light"></i> Confirm
        </el-button>
        <el-button
          size="medium"
          class="me-primary"
          @click.stop="close"
          color="#ddf0ac"
        >
          <i class="fa fa-times me-2 me-primary"></i> Cancel
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// import "animate.css";
import UserService from "../../services/user.service";
import moment from "moment";
export default {
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    data_modal: {
      type: Object,
      // eslint-disable-next-line no-unused-vars
      default(rawProps) {
        return rawProps;
      },
    },
  },
  data() {
    return {
      show: this.open_modal,
      rules: {
        lat: {
          type: "number",
          //   message: "lat must be digit",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
              if (!this.isNumeric(value)) {
                reject("lat must be digit"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
        lng: {
          type: "number",
          //   message: "lng must be digit",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            console.log(value, Number.isInteger(value));
            return new Promise((resolve, reject) => {
              if (!this.isNumeric(value)) {
                reject("lng must be digit"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
        charger_id: {
          required: true,
          message: "Please enter charger id.",
          trigger: "blur",
        },
        year_added: [
          {
            type: "number",
            // message: "year added must be digit",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              // console.log(
              //   value,
              //   !(value >= 2000 && value <= new Date().getFullYear())
              // );
              return new Promise((resolve, reject) => {
                if (!(value >= 2000 && value <= new Date().getFullYear())) {
                  reject("year added must be between 2000 and current year "); // reject with error message
                } else if (!this.isNumeric(value)) {
                  reject("year added must be digit"); // reject with error message
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        install_date: {
          type: "date",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
              if (!this.isValidDate(value)) {
                reject("install date must be valid date"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
        decommissioning_date: {
          type: "date",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            return new Promise((resolve, reject) => {
              if (!this.isValidDate(value)) {
                reject("decommissioning date must be valid date"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
        operational_date: [
          {
            type: "date",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isValidDate(value)) {
                  reject("operational date must be valid date"); // reject with error message
                } else {
                  resolve();
                }
              });
            },
          },
          {
            required: true,
            message: "Please enter operational_date.",
            trigger: "blur",
          },
        ],
        charger_type: {
          type: "enum",
          enum: [`AC`, "DC", "ac", "Ac", "aC", "dc", "Dc", "dC", ""],
          message: "charger_type must be Ac or DC",
          trigger: "blur",
        },
        charger_capacity_kW: {
          type: "integer",
          //   message: "charger capacity kW must be digit",
          trigger: "blur",
          asyncValidator: (rule, value) => {
            console.log(value);
            return new Promise((resolve, reject) => {
              if (!(value >= 1 && value <= 1000)) {
                reject("charger capacity kW must be between 1 and 1000"); // reject with error message
              } else {
                resolve();
              }
            });
          },
        },
      },
      charger_data: {
        site_title: "",
        country: "",
        lat: "",
        lng: "",
        address_comment: "",
        charger_id: "",
        year_added: "",
        install_date: "",
        operational_date: "",
        decommissioning_date: "",
        certificate_number: "",
        make: "",
        model: "",
        charger_type: "",
        charger_capacity_kW: "",
        onsite_renewable: false,
        is_included: false,
        onsite_battery: false,
        description: "",
        port_id: "",
        port_type: "",
        error_message: "",
        customer_id: 0,
      },
      chargerTypes: [
        { id: 1, title: "AC" },
        { id: 2, title: "DC" },
      ],
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    open_modal: function (newVal, oldVal) {
      this.show = newVal;
      this.charger_data = JSON.parse(this.data_modal);
      console.log(this.charger_data);
      if (
        this.charger_data.install_date != "" &&
        this.charger_data.install_date != null
      )
        this.charger_data.install_date = moment(
          this.charger_data.install_date
        ).format("yyyy-MM-DD");
      if (
        this.charger_data.decommissioning_date != "" &&
        this.charger_data.decommissioning_date != null
      ) {
        this.charger_data.decommissioning_date = moment(
          this.charger_data.decommissioning_date
        ).format("yyyy-MM-DD");
      }
      if (
        this.charger_data.operational_date != "" &&
        this.charger_data.operational_date != null
      )
        this.charger_data.operational_date = moment(
          this.charger_data.operational_date
        ).format("yyyy-MM-DD");

      this.charger_data.onsite_battery =
        this.charger_data.onsite_battery.toUpperCase() == "YES" ? true : false;
      this.charger_data.onsite_renewable =
        this.charger_data.onsite_renewable.toUpperCase() == "YES"
          ? true
          : false;
    },
  },
  methods: {
    close() {
      this.show = false;
      this.$emit("close_modal", false);
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isValidDate(dateString) {
      if (
        dateString == null ||
        dateString == "" ||
        dateString == "Invalid date"
      )
        return true;
      const dateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;
      if (dateRegex.test(dateString)) {
        const date = new Date(dateString);
        if (!isNaN(date.getTime())) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    async confirm() {
      // await this.$refs["ruleFormRef"].validate((valid, fields) => {
      //   if (valid) {
      //     // console.log("submit!");
      //     this.charger_data.error_message = [];
      //     this.$emit("confirm_modal", this.charger_data);
      //   } else {
      //     console.log("error submit!", fields);
      //   }
      // });
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid) {
          // console.log(JSON.stringify(this.charger_data));
          this.charger_data.onsite_battery = this.charger_data.onsite_battery
            ? "YES"
            : "NO";
          this.charger_data.onsite_renewable = this.charger_data
            .onsite_renewable
            ? "YES"
            : "NO";
          UserService.updateCharger(this.charger_data)
            .then(
              (response) => {
                console.log(response.data.charger);
                this.$emit("confirm_modal", response.data.charger);
              },
              (error) => {
                this.loading = false;
                console.log(error);
                if (error.response.status == 401) {
                  // this.$router.push({
                  //   path: "/",
                  // });
                }
              }
            )
            .catch((error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                // this.$router.push({
                //   path: "/",
                // });
              }
            })
            .finally(() => (this.loading = false));
        } else {
          console.log("error submit!", fields);
        }
      });
    },
  },
};
</script>

<style>
.frm-class {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 5px;
}

.el-dialog__body {
  padding: 30px 10px;
}
.el-input__inner {
  padding: 0 10px !important;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.el-form-item__label {
  font-size: 11px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.el-form-item__content > .el-checkbox {
  margin-top: 16px;
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.dtp-customer > .el-input__wrapper {
  padding: 0 10px !important;
  height: 32px !important;
}
</style>
