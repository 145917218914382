export const chargerPie = {
    series: [],
    chartOptions: {
        chart: {
            type: 'pie',
        },
        labels: [],
        legend: {
            show: true,
            position: 'right',
            horizontalAlign: 'center',
            labels: {
                colors: '#ffffff', // Set the legend text color to white
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
            }
        }],
    },
}
export const chargerDonut = {
    series: [],
    chartOptions: {
        chart: {
            type: 'donut',
        },
        labels: [],
        legend: {
            show: true,
            position: 'right',
            horizontalAlign: 'center',
            labels: {
                colors: '#ffffff', // Set the legend text color to white
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
            }
        }]
    },
}

export const locationBarChartOLD = {
    series: [{
        name: 'Charger Count',
        type: 'column',
        data: [],
        color: "#DDF0AC",
    }, ],
    chartOptions: {
        chart: {
            type: 'line',
            stacked: false,
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            width: [0, 4],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        labels: [],
        markers: {
            size: 0
        },
        xaxis: {
            title: {
                // text: "Charger by location",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                rotate: -45,
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                offsetY: 5,
                formatter: function (val) {
                    const maxLength = 10;
                    if (typeof val === 'string' && val.length > maxLength) {
                        return val.substring(0, maxLength) + '...';
                    }
                    return val;
                }
            },
        },
        yaxis: {
            min: 0,
            title: {
                text: "Number Of Chargers",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        legend: {
            position: "top",
            labels: {
                colors: "#FFFFFF",
            }
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }
        }
    },
}


export const locationBarChart = {
    series: [{
        name: 'Charger Count',
        type: 'column',
        data: [],
        color: "#DDF0AC",
    }],
    chartOptions: {
        chart: {
            type: 'line',
            stacked: false,
            zoom: {
                enabled: true, // Enable zoom functionality
                type: 'x', // Zoom along the x-axis
                autoScaleYaxis: true,
            },
            toolbar: {
                show: true,
                tools: {
                    // zoom: true,
                    zoomin: true,
                    zoomout: true,
                    // pan: true,
                    reset: true,
                },
                autoSelected: 'zoom',
            },
            height: 400, // Adjust height for better visibility of long labels
        },
        stroke: {
            width: [0, 4],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        labels: [],
        markers: {
            size: 0
        },
        xaxis: {
            title: {
                // text: "Charger by Location",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                rotate: -45, // Adjust rotation for better visibility
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                    // whiteSpace: 'normal', // Allow wrapping of text
                    // overflow: 'visible', // Prevent hiding of text
                    // textOverflow: 'clip', // Ensure full text is shown
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                offsetY: 5,
                formatter: function (val) {
                    const maxLength = 20;
                    if (typeof val === 'string' && val.length > maxLength) {
                        return val.substring(0, maxLength) + '...';
                    }
                    return val;
                }
            },
            tickAmount: 10, // Limit the number of visible ticks on the x-axis
            scrollbar: {
                enabled: true, // Enable horizontal scrolling for overflow
            },

        },
        yaxis: {
            min: 0,
            title: {
                text: "Number Of Chargers",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        legend: {
            position: "top",
            labels: {
                colors: "#FFFFFF",
            }
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }
        }
    },
}





export const capacityChart = {
    series: [{
        name: 'Charger Count',
        type: 'column',
        data: [],
        color: "#DDF0AC",
    }, ],
    chartOptions: {
        chart: {
            type: 'line',
            stacked: false,
            zoom: {
                enabled: false,
            },
        },
        stroke: {
            width: [0, 4],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        labels: [],
        markers: {
            size: 0
        },
        xaxis: {
            title: {
                text: "Charger Capacity (kW)",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            title: {
                text: "Number Of Chargers",
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
            },
        },
        legend: {
            position: "top",
            labels: {
                colors: "#FFFFFF",
            }
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;

                }
            }
        }
    },
}