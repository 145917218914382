<template>
    <div>
        <el-col :span="24">

            <div class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4">
                <clientTotal ref="grandchildComp" />
            </div>

            <div class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
            >
                <fullBreakdown ref="grandchildComp2"  />
            </div>
            <div class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4" >
                <byStateAndRegion ref="grandchildComp3"  />
            </div>
            
            <div class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4" >
                <StateRegionAndChargerType ref="grandchildComp4"  />
            </div>

        </el-col>
    </div>
</template>

<script>
import clientTotal from './clientTotal.vue';
import byStateAndRegion from './byStateAndRegion.vue';
import fullBreakdown from './fullBreakdown.vue';
import StateRegionAndChargerType from './StateRegionAndChargerType.vue';
export default {
    props: {
        parentDataFilter: {
            type: Object,
            required: false,
            default: () => ({}),
        }
    },
    data(){
        return{
            dataList : [],
        }
    },
    components:{
        clientTotal,
        byStateAndRegion,
        fullBreakdown,
        StateRegionAndChargerType,
    },

    methods: {
        emitToParent(parentDataFilter) {
            this.$refs.grandchildComp.getTableData(parentDataFilter);
            this.$refs.grandchildComp2.getTableData(parentDataFilter);
            this.$refs.grandchildComp3.getTableData(parentDataFilter);
            this.$refs.grandchildComp4.getTableData(parentDataFilter);
        },
    }


}
</script>