<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Breadcrumbs main="Live Abatement" title="default" />

  <div class="container-fluid p-0">
    <div class="row widget-grid">
      <Filter
        :stateProp="stateList"
        @filterHandle="filterHandle"
        :hasLocation="true"
      />
      <el-row class="m-0 width-100-p mt-3">
        <!-- Abatement -->
        <el-col class="d-flex align-item-center px-2" :span="24">
          <div
            class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
          >
            <!-- <el-col :span="24">
              <h5 class="text-white mt-3 ml-1">Map Heading</h5>
            </el-col> -->
            <el-col :span="24">
              <l-map
                ref="map"
                style="height: 600px; z-index: 1"
                class="rounded-3 my-3"
                v-model:zoom="map.zoom"
                :center="map.center"
              >
                <l-tile-layer
                  :url="map.url"
                  :attribution="map.attribution"
                  layer-type="base"
                  name="OpenStreetMap"
                ></l-tile-layer>
                <l-marker
                  :key="index"
                  v-for="(item, index) in dataList"
                  :lat-lng="[item.lat, item.lng]"
                  :icon="getCustomIcon(item.emission_intensity)"
                >
                  <l-tooltip>
                    <div class="custom-popup">
                      <div class="popup-header">
                        <h3>{{ item.title }}</h3>
                      </div>
                      <div class="popup-body">
                        <p>{{ item.elec_region.toUpperCase() }}</p>
                        <div class="availability">
                          <div class="indicator"></div>
                          <span class="font-13-b">
                            {{
                              item.emission_intensity == null
                                ? 0
                                : item.emission_intensity.toLocaleString()
                            }}
                          </span>
                          <span class="font-13 pl-1">
                            Emission intensity (tCO2-e/MWh)
                          </span>
                        </div>
                        <div class="availability">
                          <div class="indicator"></div>
                          <span class="font-13-b">
                            {{
                              item.abatement_ac == null
                                ? 0
                                : item.abatement_ac.toLocaleString()
                            }}
                          </span>
                          <span class="font-13 pl-1">
                            Abatement AC (tCO2-e/MWh)
                          </span>
                        </div>
                        <div class="availability">
                          <div class="indicator"></div>
                          <span class="font-13-b">
                            {{
                              item.abatement_dc == null
                                ? 0
                                : item.abatement_dc.toLocaleString()
                            }}
                          </span>
                          <span class="font-13 pl-1">
                            Abatement DC (tCO2-e/MWh)
                          </span>
                        </div>
                      </div>
                    </div>
                  </l-tooltip>
                  <!-- <l-popup :ref="'popup_' + index">
                    <div class="custom-popup">
                      <div class="popup-header">
                        <h3>{{ item.title }}</h3>
                      </div>
                      <div class="popup-body">
                        <p>{{ item.elec_region.toUpperCase() }}</p>
                        <div class="availability">
                          <div class="indicator"></div>
                          <span class="font-13-b">
                            {{
                              item.emission_intensity == null
                                ? 0
                                : item.emission_intensity.toLocaleString()
                            }}
                          </span>
                          <span class="font-13 pl-1">
                            Emission intensity (tCO2-e/MWh)
                          </span>
                        </div>
                        <div class="availability">
                          <div class="indicator"></div>
                          <span class="font-13-b">
                            {{
                              item.abatement_ac == null
                                ? 0
                                : item.abatement_ac.toLocaleString()
                            }}
                          </span>
                          <span class="font-13 pl-1">
                            Abatement AC (tCO2-e/MWh)
                          </span>
                        </div>
                        <div class="availability">
                          <div class="indicator"></div>
                          <span class="font-13-b">
                            {{
                              item.abatement_dc == null
                                ? 0
                                : item.abatement_dc.toLocaleString()
                            }}
                          </span>
                          <span class="font-13 pl-1">
                            Abatement DC (tCO2-e/MWh)
                          </span>
                        </div>
                      </div>
                    </div>
                  </l-popup> -->
                </l-marker>
              </l-map>
            </el-col>
          </div>
        </el-col>
      </el-row>
      <el-row class="m-0 width-100-p mt-3">
        <el-col class="d-flex align-item-center px-2" :span="24">
          <div class="row m-0 width-100-p">
            <div
              class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
            >
              <el-col :span="24">
                <div class="row m-0">
                  <el-col
                    class="pl-0"
                    :span="$vuetify.display.width >= 500 ? 19 : 24"
                    :class="
                      $vuetify.display.width < 500
                        ? 'x-center p-0 order-3 mt-2'
                        : ''
                    "
                  >
                    <el-input
                      size="large"
                      v-model="search"
                      placeholder="Type to search by site name, charger ID, port ..."
                      :prefix-icon="Search"
                      class="table-search"
                    />
                  </el-col>
                  <el-col
                    class="p-0"
                    :span="$vuetify.display.width >= 500 ? 5 : 24"
                    :class="
                      $vuetify.display.width < 500
                        ? 'x-center pl-1 order-2 width-100-p'
                        : 'd-flex justify-content-end'
                    "
                  >
                    <el-button
                      @click.stop="handleReload"
                      color="#ddf0ac"
                      class="height-40 rounded-3"
                      :class="$vuetify.display.width < 500 ? 'width-100-p' : ''"
                    >
                      <svg class="stroke-icon width-20 height-20">
                        <use
                          href="@/assets/svg/icon-sprite.svg#stroke-refresh"
                        ></use>
                      </svg>
                      <span class="me-primary pl-3"> Apply </span>
                    </el-button>
                  </el-col>
                  <!-- <el-col
                    class="p-0"
                    :span="$vuetify.display.width >= 750 ? 5 : 12"
                    :class="
                      $vuetify.display.width < 750
                        ? 'x-center pr-1 order-1 width-100-p'
                        : 'd-flex justify-content-end'
                    "
                  >
                    <el-button
                      color="#0D3937"
                      class="height-40 border-color-light rounded-3 ml-1"
                      :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
                    >
                      <svg class="stroke-icon width-20 height-20">
                        <use
                          href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
                        ></use>
                      </svg>
                      <span class="text-white pl-3"> Filter </span>
                    </el-button>
                  </el-col> -->
                </div>
              </el-col>
              <DataTable
                :dataProp="dataList"
                :loadingProp="loading"
                :totalRowProp="total_items"
                @tableAction="handleTableAction"
              />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  // LPopup,
} from "@vue-leaflet/vue-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import UserService from "../../../services/user.service";
import DataTable from "./dataTable.vue";
import Filter from "../common/filter.vue";

export default {
  props: {},
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    // LPopup,
    DataTable,
    Filter,
  },
  watch: {},
  computed: {
    stateList() {
      return this.states;
    },
  },
  data() {
    return {
      map: {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 10,
        center: [-37.8136, 144.9631],
        markerLatLng: [],
      },
      current_page: 1,
      perPageCount: 50,
      total_items: 100,
      search: "",
      dataList: [],
      loading: false,
      states: [],
      stateFilter: null,
      filter: {
        state: null,
        customer_id: null,
        start_date: null,
        end_date: null,
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getCustomIcon(value) {
      const color = this.getColorFromValue(value);
      return L.icon({
        iconUrl: this.getIconUrl(color),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
        shadowSize: [41, 41],
        shadowAnchor: [12, 41],
      });
    },
    getIconUrl(color) {
      const markerSvg = `
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.09 122.88"  width="30" height="30">
          <path fill="${color}" d="M105.67 61.11c.31 4.25.94 8.53 1.58 12.78A140 140 0 0 1 109 89.32c.27 6.75-.53 12.3-2.76 16.22-2.48 4.38-6.51 6.72-12.45 6.51-7.09-.13-11.45-4.11-13.42-11.46-1.27-4.73-1.46-11-.7-18.5.54-5.42 1.36-8.89 1-14.47-.45-6.33-1.57-11.32-3.4-15a11.5 11.5 0 0 0-6.27-6v59.93a8.2 8.2 0 0 1 2.3 1.64 8.4 8.4 0 0 1 2.47 6v6a2.74 2.74 0 0 1-2.77 2.69H2.74A2.74 2.74 0 0 1 0 120.14v-6a8.42 8.42 0 0 1 2.47-6A8.6 8.6 0 0 1 4 107V14.52A14.54 14.54 0 0 1 18.56 0h37.78a14.63 14.63 0 0 1 14.59 14.59V41c11.36 2.48 14.4 15.65 15.13 26.51.49 7.31-.15 7.2-.94 14.63-.77 7.13-.64 12.88.49 17.09 1.27 4.77 4 7.35 8.24 7.41h.05c3.66.12 6.09-1.22 7.52-3.75 1.69-3 2.28-7.55 2-13.31a140 140 0 0 0-1.68-14.85c-.66-4.44-1.33-8.91-1.64-13.57h-.92l-.09-5.88c-7-.68-9.64-5.39-9.64-12.16V40h-1a1.65 1.65 0 0 1-1.65-1.65v-1.89a1.65 1.65 0 0 1 1.65-1.65h4.89v-8.65a2.59 2.59 0 0 1 2.6-2.6 2.6 2.6 0 0 1 2.6 2.6v8.65h7.65v-8.65a2.6 2.6 0 0 1 2.6-2.6 2.6 2.6 0 0 1 2.61 2.6v8.65h5a1.65 1.65 0 0 1 1.65 1.65v1.91a1.65 1.65 0 0 1-1.61 1.63h-1.29v3.45c0 6.68-2.26 10.66-8.75 11.7v5.94ZM42.21 71.28h6.89a1.81 1.81 0 0 1 1.81 1.81 1.85 1.85 0 0 1-.31 1l-16.41 28a1.8 1.8 0 0 1-3.31-1.3l2.42-17.17-8.16.14A1.8 1.8 0 0 1 23.32 82a1.7 1.7 0 0 1 .25-.95l16.13-28a1.81 1.81 0 0 1 3.3 1.18l-.83 17.05ZM21.86 12.54h31.82a6 6 0 0 1 4.24 1.76c.07.08.15.15.22.24a6 6 0 0 1 1.56 4v20.3a6 6 0 0 1-6 6H21.86a6 6 0 0 1-4.23-1.76 6 6 0 0 1-1.76-4.24V18.53a6 6 0 0 1 1.76-4.24 2.4 2.4 0 0 1 .24-.21 6 6 0 0 1 4-1.54Zm46.58 98.95H7.26a3 3 0 0 0-.91.62 2.92 2.92 0 0 0-.86 2.07v3.24h64.75v-3.24a3 3 0 0 0-.86-2.07 3 3 0 0 0-.94-.63Z" style="fill-rule:evenodd"/>
        </svg>
      `;
      return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
        markerSvg
      )}`;
    },
    getColorFromValue(value) {
      let r, g;
      if (value < 0) {
        g = 1;
        r = 0;
      } else if (value < 1) {
        r = Math.floor(255 * value);
        g = Math.floor(255 * (1 - value));
      } else {
        r = g = 0;
      }
      return `rgb(${r},${g},0)`;
    },
    fetchData() {
      let filter = {
        customer_id: this.filter.customer_id,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
        search: this.search,
        state: this.filter.state == "All" ? "" : this.filter.state,
      };
      this.loading = true;
      UserService.GetLiveAbatementList(
        filter,
        this.current_page,
        this.perPageCount
      )
        .then(
          (response) => {
            this.dataList = response.data.data;
            this.states = response.data.states.map((charger) => charger.state);
            this.total_items = response.data.total_record;
            if (this.dataList.length > 0) {
              this.map.center = [this.dataList[0].lat, this.dataList[0].lng];
            }
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    handleReload() {
      this.fetchData();
    },
    handleTableAction(event) {
      if (event.kind == "changePage") {
        this.current_page = event.index;
        this.handleReload();
      } else if (event.kind == "perPageChange") {
        this.current_page = 1;
        this.perPageCount = event.index;
        this.handleReload();
      }
    },
    filterHandle(event) {
      this.filter = event;
      // console.log(this.filter);
      // this.stateFilter = event.state;
      this.fetchData();
    },
  },
};
</script>

<style>
.custom-marker {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-green {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50px;
}

.leaflet-popup {
  margin-bottom: 25px !important;
  left: 40px;
}

.custom-popup {
  width: 301px;
  font-family: Arial, sans-serif;
}

.custom-popup .popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.custom-popup .popup-header h3 {
  margin: 0;
  color: #e74c3c;
  font-size: 18px;
  word-wrap: break-word;
  white-space: normal;
}

.custom-popup .popup-body {
  padding: 10px;
}

.custom-popup .popup-body p {
  margin: 0;
  margin-bottom: 5px;
  font-size: 14px;
}

.custom-popup .popup-body .amenities {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-popup .popup-body .amenities img {
  width: 24px;
  height: 24px;
}

.custom-popup .popup-body .availability {
  display: flex;
  align-items: center;
  gap: 5px;
}

.custom-popup .popup-body .availability .indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
}

.leaflet-tooltip {
  padding: 0;
}
</style>
