<template>
    <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
        <h5 class="text-white mt-3 ml-5">Totals</h5>
        <div class="width-100-p">
            <apexchart
                ref="chart"
                type="bar"
                height="350"
                :options="chartOptions"
                :series="series"
            ></apexchart>

        </div>
        <div class="width-100-p" v-if="displayTwoChart">
            <apexchart
                ref="chart"
                type="bar"
                height="350"            
                :options="chartOptionsTwo"            
                :series="seriesTwo"
            ></apexchart>                                 
        </div>

    </div>

</template>

<script>
import { mapState } from 'vuex';
import {estimatorChart, estimatorChartTwo} from './config.js';



export default {
    computed: {
        // data from store for total tables
        ...mapState('estimator', {
            estimatorData: state => state.estimatorData
        }),
    },
    data(){
        return{
            
            series: estimatorChart.series,
            chartOptions: estimatorChart.chartOptions,

            displayTwoChart: false,

            seriesTwo: estimatorChartTwo.series,
            chartOptionsTwo: estimatorChartTwo.chartOptions,

            }
    },
    methods: {
        updateChartData(){

            try {

            
            // console.log("test is running");


            
            // when only ac or dc is selected
            if (!this.estimatorData.ac || !this.estimatorData.dc) {
                const chartData = this.estimatorData;

                // console.log("chartData", chartData.data);

                this.series[0].data = chartData.map(item => parseFloat(item.project_emission).toFixed(4)); // Project Emission
                this.series[1].data = chartData.map(item => parseFloat(item.abatement).toFixed(4)); // Abatement
                this.series[2].data = chartData.map(item => parseFloat(item.abatement_per_mwh).toFixed(4)); // Abatement per MWh

                this.chartOptions.xaxis.title.text = chartData[0].location;
                
            } else {
                this.displayTwoChart = true;
                const acChartData = this.estimatorData.ac;

                this.series[0].data = acChartData.map(item => parseFloat(item.project_emission).toFixed(4) ); // Project Emission
                this.series[1].data = acChartData.map(item => parseFloat(item.abatement).toFixed(4) ); // Abatement
                this.series[2].data = acChartData.map(item => parseFloat(item.abatement_per_mwh).toFixed(4) ); // Abatement per MWh

                this.chartOptions.xaxis.title.text = acChartData[0].location;

                const dcChartData = this.estimatorData.dc;

                this.seriesTwo[0].data = dcChartData.map(item => parseFloat(item.project_emission).toFixed(4) ); // Project Emission
                this.seriesTwo[1].data = dcChartData.map(item => parseFloat(item.abatement).toFixed(4) ); // Abatement
                this.seriesTwo[2].data = dcChartData.map(item => parseFloat(item.abatement_per_mwh).toFixed(4) ); // Abatement per MWh

                this.chartOptionsTwo.xaxis.title.text = dcChartData[0].location;
            }
            } catch (error) {
                console.error("error:",error);
                
            }

        },  

    },
    mounted(){
        this.updateChartData();
    },
    watch:{
        estimatorData: {
            handler: 'updateChartData',
            deep: true // use deep to watch nested changes
        }
    },

}

</script>
