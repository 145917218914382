<template>
  <div id="sidebar-menu">
    <!-- {{layoutobject}}
          fdsfdsf{{[layoutobject.split(' ').includes('horizontal-wrapper')]}} -->
    <ul
      class="sidebar-links custom-scrollbar"
      id="myDIV"
      :style="[
        layoutobject.split(' ').includes('horizontal-wrapper')
          ? layout.settings.layout_type == 'rtl'
            ? { '  -right': margin + 'px' }
            : { 'margin-left': margin + 'px' }
          : { margin: '0px' },
      ]"
    >
      <li class="back-btn">
        <router-link to="/">
          <img
            class="img-fluid"
            src="../../assets/images/logo/logo-icon.png"
            alt=""
        /></router-link>
        <div class="mobile-back text-end">
          <span>Back</span
          ><i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>
      <li class="pt-2 pl-5">
        <div class="sidebar-part">
          <h6 class="lan-1 me-primary-light">GENERAL</h6>
        </div>
      </li>
      <!-- Dashboard -->
      <li class="sidebar-list">
        <label class="badge badge-light-primary"> </label>
        <router-link
          class="sidebar-link sidebar-title text-white"
          to="/dashboard"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-new-home"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-new-home"></use>
          </svg>
          <span class="lan-3 text-white">Home </span>
        </router-link>
      </li>
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <label class="badge badge-light-primary"> </label>
        <router-link
          class="sidebar-link sidebar-title text-white"
          to="/summary"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-home"></use>
          </svg>
          <span class="lan-3 text-white"> Summary </span>
        </router-link>
      </li>

      <!-- Auditor Reports -->
      <li class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin">
        <label class="badge badge-light-primary"> </label>
        <router-link
          class="sidebar-link sidebar-title text-white"
          to="/dashboard-auditor-reports"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-home"></use>
          </svg>
          <span class="lan-3 text-white"> Auditor Reports </span>
        </router-link>
      </li>
      <!-- Auditor Reports -->



      <li class="pt-2 pl-5">
        <div class="sidebar-part">
          <h6 class="lan-1 me-primary-light">INSIGHTS</h6>
        </div>
      </li>
      <!-- Charger -->
      <li class="sidebar-list">
        <label class="badge badge-light-primary"> </label>
        <router-link
          class="sidebar-link sidebar-title text-white"
          to="/dashboard-chargers"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-others"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-others"></use>
          </svg>
          <span class="lan-3 text-white">Chargers </span>
        </router-link>
      </li>
      <!-- Session -->
      <li class="sidebar-list">
        <label class="badge badge-light-primary"> </label>
        <router-link
          class="sidebar-link sidebar-title text-white"
          to="/dashboard-sessions"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-widget"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-widget"></use>
          </svg>
          <span class="lan-3 text-white"> Sessions </span>
        </router-link>
      </li>
      <!-- Live Abatement -->
      <li class="sidebar-list">
        <label class="badge badge-light-primary"> </label>
        <router-link
          class="sidebar-link sidebar-title text-white"
          to="/dashboard-abatement"
        >
          <svg class="stroke-icon">
            <use
              href="@/assets/svg/icon-sprite.svg#stroke-internationalization"
            ></use>
          </svg>
          <svg class="fill-icon">
            <use
              href="@/assets/svg/icon-sprite.svg#fill-internationalization"
            ></use>
          </svg>
          <span class="lan-3 text-white"> Live Abatement </span>
        </router-link>
      </li>
      <!-- TimeOfDay -->
      <li class="sidebar-list">
        <label class="badge badge-light-primary"> </label>
        <router-link
          class="sidebar-link sidebar-title text-white"
          to="/dashboard-time-of-day"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-new-time"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-new-time"></use>
          </svg>
          <span class="lan-3 text-white"> Time of Day </span>
        </router-link>
      </li>

      <!-- Estimator -->
      <li class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin">
        <label class="badge badge-light-primary"> </label>
        <router-link
          class="sidebar-link sidebar-title text-white"
          to="/dashboard-estimator"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-home"></use>
          </svg>
          <span class="lan-3 text-white"> Estimator </span>
        </router-link>
      </li>
      <!-- Estimator -->

      <!-- dashboard old -->
      <!-- <li class="sidebar-list">
        <label class="badge badge-light-primary"> </label>
        <router-link
          class="sidebar-link sidebar-title text-white"
          to="/dashboard-old"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-home"></use>
          </svg>
          <span class="lan-3 text-white">Home Old</span>
        </router-link>
      </li> -->
      <!-- Users -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <router-link class="sidebar-link sidebar-title text-white" to="/users">
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-user"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-user"></use>
          </svg>
          <span class="text-white"> Users </span>
        </router-link>
      </li>
      <!-- Customers -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <router-link class="sidebar-link sidebar-title" to="/customers">
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-user"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-user"></use>
          </svg>
          <span class="text-white"> Customers </span>
        </router-link>
      </li>
      <li
        class="pt-2 pl-5"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <div>
          <h6 class="lan-1 me-primary-light">Input</h6>
        </div>
      </li>
      <!-- Nemotion Validation -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <label class="badge badge-light-primary"> </label>
        <router-link
          class="sidebar-link sidebar-title text-white"
          to="/nemotion-validation"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-home"></use>
          </svg>
          <span class="lan-3 text-white"> Nemotion Validation </span>
        </router-link>
      </li>
      <!-- Baseline Params -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <router-link class="sidebar-link sidebar-title" to="/baseline-params">
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-to-do"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-to-do"></use>
          </svg>
          <span class="text-white"> Baseline Params </span>
        </router-link>
      </li>
      <!-- Site Baseline Params -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <router-link
          class="sidebar-link sidebar-title"
          to="/site-baseline-params"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-to-do"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-to-do"></use>
          </svg>
          <span class="text-white"> Site Baseline Params </span>
        </router-link>
      </li>
      <!-- Participants -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <router-link class="sidebar-link sidebar-title" to="/participants">
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-sample-page"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-sample-page"></use>
          </svg>
          <span class="text-white"> Participants </span>
        </router-link>
      </li>
      <!-- History Log -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <router-link class="sidebar-link sidebar-title" to="/history-logs">
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-blog"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-blog"></use>
          </svg>
          <span class="text-white"> History Log </span>
        </router-link>
      </li>
      <!-- On Site Renewable -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <router-link class="sidebar-link sidebar-title" to="/on-site-renewable">
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-to-do"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-to-do"></use>
          </svg>
          <span class="text-white"> Onsite Renewable </span>
        </router-link>
      </li>
      <!-- Monitoring Periods -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <router-link
          class="sidebar-link sidebar-title"
          to="/monitoring-periods"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-to-do"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-to-do"></use>
          </svg>
          <span class="text-white"> Monitoring Periods </span>
        </router-link>
      </li>
      <!-- Battery Intensities -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <router-link
          class="sidebar-link sidebar-title"
          to="/battery-intensities"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-to-do"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-to-do"></use>
          </svg>
          <span class="text-white"> Battery Intensities </span>
        </router-link>
      </li>
      <!-- Vehicles -->
      <li
        class="sidebar-list"
        v-if="$store.state.auth.user != null && $store.state.auth.user.is_admin"
      >
        <router-link class="sidebar-link sidebar-title" to="/vehicles">
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-to-do"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-to-do"></use>
          </svg>
          <span class="text-white"> Vehicles </span>
        </router-link>
      </li>

      <li class="pt-2 pl-5">
        <div class="sidebar-part">
          <h6 class="lan-1 me-primary-light">Operation</h6>
        </div>
      </li>
      <!-- Charger -->
      <li class="sidebar-list">
        <router-link
          class="sidebar-link sidebar-title"
          v-if="
            $store.state.auth.user != null && $store.state.auth.user.is_admin
          "
          :to="'/charging-sites'"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-social"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-social"></use>
          </svg>
          <span class="text-white"> Chargers </span>
        </router-link>
        <router-link
          class="sidebar-link sidebar-title"
          v-if="
            $store.state.auth.user != null && !$store.state.auth.user.is_admin
          "
          :to="'/upload-chargers'"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-social"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-social"></use>
          </svg>
          <span class="text-white"> Chargers </span>
        </router-link>
      </li>
      <!-- Session -->
      <li class="sidebar-list">
        <!-- <a class="sidebar-link sidebar-title" href="#" target="_blank"> -->
        <router-link
          class="sidebar-link sidebar-title"
          v-if="
            $store.state.auth.user != null && $store.state.auth.user.is_admin
          "
          :to="'/sessions'"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-form"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-form"></use>
          </svg>
          <span class="text-white"> Sessions </span>
        </router-link>
        <router-link
          class="sidebar-link sidebar-title"
          v-if="
            $store.state.auth.user != null && !$store.state.auth.user.is_admin
          "
          :to="'/upload-sessions'"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-builders"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-builders"></use>
          </svg>
          <span class="text-white"> Sessions </span>
        </router-link>
      </li>
      <!-- Fleet -->
      <li class="sidebar-list">
        <!-- <router-link
          class="sidebar-link sidebar-title"
          v-if="
            $store.state.auth.user != null && $store.state.auth.user.is_admin
          "
          :to="'/fleets'"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-form"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-form"></use>
          </svg>
          <span class="text-white"> Fleet </span>
        </router-link> -->
        <router-link
          class="sidebar-link sidebar-title"
          v-if="
            $store.state.auth.user != null &&
            !$store.state.auth.user.is_admin &&
            !$store.state.auth.user.private_fleet
          "
          :to="'/upload-fleet'"
        >
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-to-do"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-to-do"></use>
          </svg>
          <span class="text-white"> Fleet </span>
        </router-link>
      </li>
      <li
        class="pt-2 pl-5"
        v-if="
          $store.state.auth.user != null && !$store.state.auth.user.is_auditor
        "
      >
        <div class="sidebar-part">
          <h6 class="lan-1 me-primary-light">Support</h6>
        </div>
      </li>
      <!-- Support Ticket -->
      <li
        class="sidebar-list"
        v-if="
          $store.state.auth.user != null && !$store.state.auth.user.is_auditor
        "
      >
        <!-- <a class="sidebar-link sidebar-title" href="#" target="_blank"> -->
        <router-link
          class="sidebar-link sidebar-title"
          v-if="
            $store.state.auth.user != null && $store.state.auth.user.is_admin
          "
          :to="'/supports'"
        >
          <svg class="stroke-icon">
            <use
              href="@/assets/svg/icon-sprite.svg#stroke-support-tickets"
            ></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-support-tickets"></use>
          </svg>
          <span class="text-white"> Support Ticket </span>
        </router-link>
        <router-link
          v-if="
            $store.state.auth.user != null && !$store.state.auth.user.is_admin
          "
          class="sidebar-link sidebar-title"
          :to="'/tickets'"
        >
          <svg class="stroke-icon">
            <use
              href="@/assets/svg/icon-sprite.svg#stroke-support-tickets"
            ></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-support-tickets"></use>
          </svg>
          <span class="text-white"> Support Ticket </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { layoutClasses } from "../../constants/layout";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Navmenu",
  data() {
    return {
      layoutobj: {},
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      sidebar: (state) => state.layout.sidebarType,
      activeoverlay: (state) => state.menu.activeoverlay,
      togglesidebar: (state) => state.menu.togglesidebar,
      width: (state) => state.menu.width,
      height: (state) => state.menu.height,
      margin: (state) => state.menu.margin,
      menuWidth: (state) => state.menu.menuWidth,
    }),
    layoutobject: {
      get: function () {
        return JSON.parse(
          JSON.stringify(
            layoutClasses.find(
              (item) => Object.keys(item).pop() === this.layout.settings.layout
            )
          )
        )[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find(
          (item) => Object.keys(item).pop() === this.layout.settings.layout
        );
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
        return this.layoutobj;
      },
    },
  },
  watch: {
    width() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
      window.addEventListener("scroll", this.handleScroll);
      this.handleScroll();
      if (window.innerWidth < 992) {
        const newlayout = JSON.parse(
          JSON.stringify(this.layoutobject).replace(
            "horizontal-wrapper",
            "compact-wrapper"
          )
        );
        document.querySelector(".page-wrapper").className =
          "page-wrapper " + newlayout;
        this.$store.state.menu.margin = 0;
      } else {
        document.querySelector(".page-wrapper").className =
          "page-wrapper " + this.layoutobject;
      }

      if (
        (window.innerWidth < 1199 && this.layout.settings.layout === "Tokyo") ||
        (window.innerWidth < 1199 &&
          this.layout.settings.layout === "Moscow") ||
        (window.innerWidth < 1199 && this.layout.settings.layout === "Rome")
      ) {
        this.menuItems.filter((menuItem) => {
          menuItem.active = false;
        });
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$store.state.menu.width < 991) {
      this.layout.settings.layout = "Dubai";
      this.margin = 0;
    }
    setTimeout(() => {
      const elmnt = document.getElementById("myDIV");
      this.$store.state.menu.menuWidth = elmnt == null ? 0 : elmnt.offsetWidth;
      this.$store.state.menu.menuWidth > window.innerWidth
        ? ((this.$store.state.menu.hideRightArrow = false),
          (this.$store.state.menu.hideLeftArrowRTL = false))
        : ((this.$store.state.menu.hideRightArrow = false),
          (this.$store.state.menu.hideLeftArrowRTL = true));
    }, 500);
    this.layoutobject = layoutClasses.find(
      (item) => Object.keys(item).pop() === this.layout.settings.layout
    );
    this.layoutobject = JSON.parse(JSON.stringify(this.layoutobject))[
      this.layout.settings.layout
    ];
  },
  // destroyed() {
  //   window.removeEventListener('resize', this.handleResize);
  // },
  mounted() {
    this.menuItems.filter((items) => {
      if (items.path === this.$route.path)
        this.$store.dispatch("menu/setActiveRoute", items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.path === this.$route.path)
          this.$store.dispatch("menu/setActiveRoute", subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === this.$route.path)
            this.$store.dispatch("menu/setActiveRoute", subSubItems);
        });
      });
    });
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 400) {
        if (
          this.layoutobject.split(" ").pop() === "material-type" ||
          this.layoutobject.split(" ").pop() === "advance-layout"
        )
          document.querySelector(".sidebar-main").className =
            "sidebar-main hovered";
      } else {
        if (document.getElementById("sidebar-main"))
          document.querySelector(".sidebar-main").className = "sidebar-main";
      }
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setNavActive", item);
      if (
        this.layoutobject.split(" ").includes("compact-sidebar") &&
        window.innerWidth > 991
      ) {
        if (this.menuItems.some((menuItem) => menuItem.active === true)) {
          this.$store.state.menu.activeoverlay = true;
        } else {
          this.$store.state.menu.activeoverlay = false;
        }
      }
    },
    hidesecondmenu() {
      if (window.innerWidth < 991) {
        (this.$store.state.menu.activeoverlay = false),
          (this.$store.state.menu.togglesidebar = false);
        this.menuItems.filter((menuItem) => {
          menuItem.active = false;
        });
      } else if (this.layoutobject.split(" ").includes("compact-sidebar")) {
        (this.$store.state.menu.activeoverlay = false),
          this.menuItems.filter((menuItem) => {
            menuItem.active = false;
          });
      }
    },
    handleResize() {
      this.$store.state.menu.width = window.innerWidth - 450;
    },
  },
};
</script>

<style>
.stroke-icon,
.stroke-icon use,
.fill-icon,
.fill-icon use {
  fill: white;
  stroke: white;
}
</style>
