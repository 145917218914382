const state = {
    clientTotalDataList: [],
    fullBreakdownDataList: [],
    StateRegionDataList: [],
    StateRegionChargerTypeDataList: [],
}

const mutations = {
    set_clientTotal(state,data) {
        state.clientTotalDataList = data;
    },

    set_fullBreakdown(state,data) {
        state.fullBreakdownDataList = data;
    },

    set_StateRegion(state,data) {
        state.StateRegionDataList = data;
    },

    set_StateRegionChargerType(state,data) {
        state.StateRegionChargerTypeDataList = data;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
}