<!-- eslint-disable vue/no-mutating-props -->
<template>
  <el-dialog
    v-model="show"
    width="600"
    :fullscreen="$vuetify.display.width <= 600 ? true : false"
    class="public-style"
    destroy-on-close
    align-center
    @close="close"
  >
    <el-form
      ref="ruleFormRef"
      :rules="rules"
      :model="baseline_data"
      :style="[$vuetify.display.width < 600 ? 'height: 550;' : '']"
      :label-position="`top`"
    >
      <el-space fill style="width: 100%">
        <el-alert type="info" class="alert-style" show-icon :closable="false">
          <p class="ma-0 text-white">Site Baseline Param :</p>
        </el-alert>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item class="label-style" prop="country" label="Country: ">
              <el-input v-model="baseline_data.country" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="charger_type"
              label="Charger Type: "
            >
              <!-- <el-input v-model="baseline_data.charger_type" /> -->
              <el-select
                v-model="baseline_data.charger_type"
                placeholder="Select"
                class="w-100"
              >
                <el-option
                  v-for="item in chargerTypes"
                  :key="item.id"
                  :label="item.title"
                  :value="item.title"
                >
                  <span style="float: left">{{ item.title }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="dispatch_int"
              label="Dispatch Int: "
            >
              <el-input v-model="baseline_data.dispatch_int" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="fuel_emission_intensity"
              label="Fuel Emission Intensity: "
            >
              <el-input v-model="baseline_data.fuel_emission_intensity" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="charger_efficiency"
              label="Charger Efficiency: "
            >
              <el-input v-model="baseline_data.charger_efficiency" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style" prop="afec" label="Afec: ">
              <el-input v-model="baseline_data.afec" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item class="label-style" prop="mpg" label="Mpg: ">
              <el-input v-model="baseline_data.mpg" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 8 : 24">
            <el-form-item
              class="label-style"
              prop="tech_improvement_rate"
              label="Tech Improvement Rate: "
            >
              <el-input v-model="baseline_data.tech_improvement_rate" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="fuel_energy_density_MJ_gal"
              label="Fuel Energy Density MJ Gal: "
            >
              <el-input v-model="baseline_data.fuel_energy_density_MJ_gal" />
            </el-form-item>
          </el-col>
          <el-col :span="$vuetify.display.width >= 500 ? 12 : 24">
            <el-form-item
              class="label-style"
              prop="fuel_energy_density_MJ_kWh"
              label="Fuel Energy Density MJ kWh: "
            >
              <el-input v-model="baseline_data.fuel_energy_density_MJ_kWh" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="$vuetify.display.width >= 500 ? 24 : 24">
            <el-form-item
              class="label-style"
              prop="charging_site_id"
              label="Charging Site: "
            >
              <!-- <el-input v-model="user_data.customer_id" /> -->
              <el-select
                v-model="baseline_data.charging_site_id"
                placeholder="Select"
                filterable
                class="w-100"
                remote
                :remote-method="remoteMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in charging_sites"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.title }}</span>
                  <span
                    style="
                      float: right;
                      color: var(--el-text-color-secondary);
                      font-size: 13px;
                    "
                  >
                    {{ item.city }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-space>
    </el-form>
    <!-- </el-row> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button
          size="medium"
          class="me-primary-light"
          @click.stop="confirm"
          color="#234B49"
        >
          <i class="fa fa-check me-2 me-primary-light"></i> Confirm
        </el-button>
        <el-button
          size="medium"
          class="me-primary"
          @click.stop="close"
          color="#ddf0ac"
        >
          <i class="fa fa-times me-2 me-primary"></i> Cancel
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
// import "animate.css";
import UserService from "../../services/user.service";
// import moment from "moment";
export default {
  props: {
    open_modal: {
      type: Boolean,
      default: false,
    },
    data_modal: {
      type: Object,
      // eslint-disable-next-line no-unused-vars
      default(rawProps) {
        return rawProps;
      },
    },
  },
  data() {
    return {
      show: this.open_modal,
      rules: {
        country: {
          required: true,
          message: "Please enter country.",
          trigger: "blur",
        },
        dispatch_int: [
          {
            required: true,
            message: "Please enter dispatch_int.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("dispatch_int must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        charger_type: {
          type: "enum",
          enum: ["AC", "DC"],
          message: "charger_type must be Ac or DC",
          trigger: "blur",
        },
        fuel_emission_intensity: [
          {
            required: true,
            message: "Please enter fuel_emission_intensity.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("fuel_emission_intensity must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        afec: [
          {
            required: true,
            message: "Please enter afec.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("afec must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        mpg: [
          {
            required: true,
            message: "Please enter mpg.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("mpg must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        charger_efficiency: [
          {
            required: true,
            message: "Please enter charger_efficiency.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("charger_efficiency must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        tech_improvement_rate: [
          {
            required: true,
            message: "Please enter tech_improvement_rate.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("tech_improvement_rate must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        fuel_energy_density_MJ_gal: [
          {
            required: true,
            message: "Please enter fuel_energy_density_MJ_gal.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("fuel_energy_density_MJ_gal must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
        fuel_energy_density_MJ_kWh: [
          {
            required: true,
            message: "Please enter fuel_energy_density_MJ_kWh.",
            trigger: "blur",
          },
          {
            type: "number",
            trigger: "blur",
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                if (!this.isNumeric(value)) {
                  reject("fuel_energy_density_MJ_kWh must be digit");
                } else {
                  resolve();
                }
              });
            },
          },
        ],
      },
      baseline_data: {
        id: 0,
        country: "",
        dispatch_int: 0,
        charger_type: "AC",
        fuel_emission_intensity: 0.0,
        afec: 0.0,
        mpg: 0.0,
        charger_efficiency: 0.0,
        tech_improvement_rate: 0.0,
        fuel_energy_density_MJ_gal: 0.0,
        fuel_energy_density_MJ_kWh: 0.0,
      },
      chargerTypes: [
        { id: 1, title: "AC" },
        { id: 2, title: "DC" },
      ],
      primarySites: [],
      charging_sites: [],
      loading: false,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    open_modal: function (newVal, oldVal) {
      this.show = newVal;
      if (newVal == true) {
        this.baseline_data = JSON.parse(this.data_modal);
        console.log(this.baseline_data);
        this.fetchChargingSiteData("", 50);
      }
    },
  },
  methods: {
    async remoteMethod(search) {
      if (search.length >= 3) {
        this.fetchChargingSiteData(search, 0);
      } else {
        this.charging_sites = this.primarySites.charging_sites;
      }
    },
    async fetchChargingSiteData(search, perPage, site_id = null) {
      this.loading = true;
      await UserService.getChargingSites(null, search, 1, perPage, site_id)
        .then(
          (response) => {
            if (perPage == 50) {
              this.primarySites = response.data;
              this.charging_sites = response.data.charging_sites;
              const exists = this.primarySites.charging_sites.some(
                (site) => site.id === this.baseline_data.charging_site_id
              );
              if (this.baseline_data.id != 0 && !exists) {
                this.fetchChargingSiteData(
                  "",
                  1,
                  this.baseline_data.charging_site_id
                );
              }
            } else if (perPage == 1) {
              if (
                response.data.charging_site != undefined &&
                response.data.charging_site != null
              ) {
                // const exists = this.primarySites.charging_sites.some(
                //   (site) => site.id === response.data.charging_site.id
                // );
                // if (!exists) {
                this.primarySites.charging_sites.push(
                  response.data.charging_site
                );
                // }
                // this.charging_sites.push(response.data.charging_site);
              }
            } else {
              this.charging_sites = response.data.charging_sites;
            }
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    isNewUser() {
      if (this.baseline_data.id == 0) return true;
      else return false;
    },
    close() {
      this.show = false;
      this.$emit("close_modal", false);
    },
    async confirm() {
      await this.$refs["ruleFormRef"].validate((valid, fields) => {
        if (valid) {
          UserService.SetSiteBaselineParam(this.baseline_data)
            .then(
              (response) => {
                this.$emit("confirm_modal", response.data.site_baseline_param);
              },
              (error) => {
                // this.loading = false;
                console.log(error);
                if (error.response.status == 401) {
                  this.$router.push({
                    path: "/",
                  });
                }
              }
            )
            .catch((error) => {
              // this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
            })
            .finally(() => (this.loading = false));
        } else {
          console.log("error submit!", fields);
        }
      });
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 30px 10px;
}
.el-input__inner {
  padding: 0 10px !important;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.el-form-item__label {
  font-size: 11px !important;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.el-form-item__content > .el-checkbox {
  margin-top: 16px;
  margin-bottom: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
