<template>
  <el-col class="mb-2 p-0" :span="24">
    <div class="row m-0">
      <el-col
        :span="
          $vuetify.display.width >= 1300
            ? 12
            : $vuetify.display.width < 950
            ? 24
            : sessionFilter == 1
            ? 11
            : 10
        "
        :class="$vuetify.display.width < 750 ? 'x-center' : ''"
      >
        <el-radio-group
          v-model="sessionFilter"
          text-color="#ffff"
          fill="#234B49"
          :class="$vuetify.display.width < 750 ? 'width-100-p' : ''"
        >
          <el-radio-button
            class="start-period all-group-by text-center"
            label="1"
            :class="$vuetify.display.width < 750 ? 'width-50-p' : ''"
          >
            Sessions
          </el-radio-button>
          <el-radio-button
            class="site-group-by text-center end-period"
            label="2"
            :class="$vuetify.display.width < 750 ? 'width-50-p' : ''"
          >
            {{
              sessionFilter == 2 &&
              $vuetify.display.width > 1006 &&
              $vuetify.display.width < 1037
                ? "Unallocated"
                : "Unallocated Sessions"
            }}
          </el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col
        :span="
          $vuetify.display.width >= 1300
            ? 12
            : $vuetify.display.width < 950
            ? 24
            : sessionFilter == 1
            ? 13
            : 14
        "
        :class="
          $vuetify.display.width < 950 || $vuetify.display.width >= 1300
            ? 'x-end'
            : 'x-end'
        "
      >
        <el-radio-group
          v-model="selectedGroupBy"
          text-color="#ffff"
          fill="#234B49"
          :class="$vuetify.display.width < 950 ? 'width-100-p mt-2' : ''"
        >
          <el-radio-button
            class="start-period all-group-by text-center"
            label="1"
            :class="
              $vuetify.display.width < 950
                ? sessionFilter == 2
                  ? 'width-20-p'
                  : 'width-25-p'
                : ''
            "
          >
            All
          </el-radio-button>
          <el-radio-button
            class="start-period all-group-by text-center"
            label="2"
            :class="
              $vuetify.display.width < 950
                ? sessionFilter == 2
                  ? 'width-20-p'
                  : 'width-25-p'
                : ''
            "
          >
            {{
              ($vuetify.display.width >= 1007 &&
                $vuetify.display.width <= 1155) ||
              ($vuetify.display.width > 750 && $vuetify.display.width <= 785) ||
              ($vuetify.display.width < 530 && sessionFilter == 2)
                ? "Region"
                : "By Region"
            }}
          </el-radio-button>
          <el-radio-button
            class="site-group-by text-center"
            label="3"
            :class="
              $vuetify.display.width < 950
                ? sessionFilter == 2
                  ? 'width-20-p'
                  : 'width-25-p'
                : ''
            "
          >
            {{
              ($vuetify.display.width >= 1007 &&
                $vuetify.display.width <= 1155) ||
              ($vuetify.display.width > 750 && $vuetify.display.width <= 785) ||
              ($vuetify.display.width < 530 && sessionFilter == 2)
                ? "Charger"
                : "By Charger"
            }}
          </el-radio-button>
          <el-radio-button
            class="charger-group-by text-center end-period"
            label="4"
            :class="
              $vuetify.display.width < 950
                ? sessionFilter == 2
                  ? 'width-20-p'
                  : 'width-25-p'
                : ''
            "
          >
            {{
              ($vuetify.display.width >= 1007 &&
                $vuetify.display.width <= 1155) ||
              ($vuetify.display.width > 750 && $vuetify.display.width <= 785) ||
              ($vuetify.display.width < 530 && sessionFilter == 2)
                ? "Site"
                : "By Site"
            }}
          </el-radio-button>
          <el-radio-button
            v-if="sessionFilter == 2"
            class="charger-group-by text-center end-period"
            label="5"
            :class="
              $vuetify.display.width < 950
                ? sessionFilter == 2
                  ? 'width-20-p'
                  : 'width-25-p'
                : ''
            "
          >
            {{
              ($vuetify.display.width >= 1007 &&
                $vuetify.display.width <= 1155) ||
              ($vuetify.display.width > 750 && $vuetify.display.width <= 785) ||
              ($vuetify.display.width < 530 && sessionFilter == 2)
                ? "Error"
                : "By Error"
            }}
          </el-radio-button>
        </el-radio-group>
      </el-col>
      <!-- <el-col
        :span="
          $vuetify.display.width >= 1300
            ? 4
            : $vuetify.display.width < 750
            ? 24
            : 8
        "
        class="d-flex align-item-center"
        :class="$vuetify.display.width < 750 ? 'x-center mt-2' : 'justify-end'"
        v-if="$vuetify.display.width < 750 || $vuetify.display.width >= 1300"
      >
        <div class="m-0" v-if="$vuetify.display.width >= 1300">
          <el-button
            color="#0D3937"
            class="height-40 border-color-light rounded-3 ml-1"
          >
            <svg class="stroke-icon width-20 height-20">
              <use
                href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
              ></use>
            </svg>
            <span class="text-white pl-3"> 1Filter </span>
          </el-button>
        </div>
        <el-row
          class="m-0 p-0 width-100-p"
          v-else-if="$vuetify.display.width >= 750"
        >
          <el-col :span="12" class="mt-2 px-1 width-100-p">
            <el-button
              color="#0D3937"
              class="x-y-center height-40 border-color-light rounded-3 width-100-p"
            >
              <svg class="stroke-icon width-20 height-20">
                <use
                  href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
                ></use>
              </svg>
              <span class="text-white pl-3"> 2Filter </span>
            </el-button>
          </el-col>
        </el-row>
      </el-col> -->
    </div>
  </el-col>
  <el-col :span="24">
    <div class="row m-0">
      <el-col
        class="pl-0"
        :span="
          $vuetify.display.width >= 1300
            ? pendingSelectionList.length > 0
              ? 16
              : 20
            : $vuetify.display.width >= 750
            ? pendingSelectionList.length > 0
              ? 16
              : 20
            : 24
        "
        :class="$vuetify.display.width < 750 ? 'x-center p-0 order-3 mt-2' : ''"
      >
        <el-row class="width-100-p m-0">
          <el-col
            class="pr-2"
            :span="
              $vuetify.display.width <= 500
                ? 4
                : $vuetify.display.width >= 1400
                ? 2
                : 3
            "
          >
            <el-button
              @click="handleExportFile"
              color="#0D3937"
              class="height-40 border-color-light rounded-3 width-100-p mr-1"
            >
              <i class="text-white fa fa-cloud-download"></i>
            </el-button>
          </el-col>
          <el-col
            :span="
              $vuetify.display.width <= 500
                ? 20
                : $vuetify.display.width >= 1400
                ? 22
                : 21
            "
          >
            <el-input
              size="large"
              v-model="search"
              placeholder="Type to search by site name, charger ID, port ..."
              :prefix-icon="Search"
              class="table-search"
            />
          </el-col>
        </el-row>
      </el-col>
      <el-col
        class="p-0"
        :span="
          $vuetify.display.width >= 1300 && pendingSelectionList.length > 0
            ? 4
            : $vuetify.display.width >= 1300 && pendingSelectionList.length == 0
            ? 4
            : $vuetify.display.width >= 750 && pendingSelectionList.length > 0
            ? 4
            : $vuetify.display.width >= 750 && pendingSelectionList.length == 0
            ? 4
            : pendingSelectionList.length > 0
            ? 12
            : 24
        "
        :class="$vuetify.display.width < 750 ? 'x-center order-1' : ''"
      >
        <el-button
          color="#ddf0ac"
          @click="handleRefresh"
          class="height-40 width-100-p rounded-3"
        >
          <svg class="stroke-icon width-20 height-20">
            <use href="@/assets/svg/icon-sprite.svg#stroke-refresh"></use>
          </svg>
          <span class="me-primary pl-3"> Apply </span>
        </el-button>
      </el-col>
      <el-col
        v-if="pendingSelectionList.length > 0"
        class="pr-0"
        :span="
          $vuetify.display.width >= 1300 && pendingSelectionList.length > 0
            ? 4
            : $vuetify.display.width >= 1300 && pendingSelectionList.length == 0
            ? 4
            : $vuetify.display.width >= 750 && pendingSelectionList.length > 0
            ? 4
            : $vuetify.display.width >= 750 && pendingSelectionList.length == 0
            ? 4
            : pendingSelectionList.length > 0
            ? 12
            : 24
        "
        :class="$vuetify.display.width < 750 ? 'x-center order-1' : ''"
      >
        <el-button
          @click.stop="pendingDelete"
          color="#0D3937"
          class="height-40 border-color-light width-100-p rounded-3 ml-1"
        >
          <svg class="stroke-icon width-20 height-20">
            <use
              href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
            ></use>
          </svg>
          <span class="text-white pl-3"> Delete </span>
        </el-button>
      </el-col>
      <!-- <el-col
        v-if="$vuetify.display.width <= 1300"
        class="pr-0"
        :span="
          $vuetify.display.width >= 750 && pendingSelectionList.length > 0
            ? 4
            : $vuetify.display.width >= 750 && pendingSelectionList.length == 0
            ? 4
            : pendingSelectionList.length > 0
            ? 8
            : 12
        "
        :class="$vuetify.display.width < 750 ? 'x-center order-2' : ''"
      >
        <el-button
          color="#0D3937"
          class="height-40 border-color-light width-100-p rounded-3 ml-1"
        >
          <svg class="stroke-icon width-20 height-20">
            <use
              href="@/assets/svg/icon-sprite.svg#stroke-filter-location"
            ></use>
          </svg>
          <span class="text-white pl-3"> 3Filter </span>
        </el-button>
      </el-col> -->
    </div>
  </el-col>
  <el-col :span="24">
    <div
      class="ma-0"
      v-loading="loading"
      :element-loading-spinner="svg"
      element-loading-svg-view-box="-10, -10, 50, 50"
      element-loading-background="rgba(122, 122, 122, 0.8)"
    >
      <el-table
        v-if="selectedGroupBy == '1'"
        :data="sessionDataList"
        :fit="true"
        height="300"
        class="my-2 width-100-p data-table"
        stripe
        scrollbar-always-on
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="
            sessionFilter == '2' &&
            $store.state.auth.user != null &&
            !$store.state.auth.user.is_auditor
          "
          type="selection"
          width="55"
        />
        <el-table-column
          :label="'Site Name'"
          :prop="'site_title'"
          :width="$vuetify.display.width <= 1900 ? 200 : ''"
          sortable
        />
        <el-table-column
          v-if="
            $store.state.auth.user != null && $store.state.auth.user.is_admin
          "
          :label="'Customer'"
          :prop="'customer'"
          :width="$vuetify.display.width <= 1900 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Postcode'"
          :prop="'postcode'"
          :width="$vuetify.display.width <= 1900 ? 170 : ''"
          sortable
        />
        <el-table-column
          :label="'Charger ID'"
          :prop="sessionFilter == '2' ? 'charger_id' : 'charger_name'"
          :width="$vuetify.display.width <= 1900 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Charger Capacity (kW)'"
          :prop="'charger_capacity_kW'"
          :width="$vuetify.display.width <= 1900 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Port ID'"
          :prop="'port'"
          :width="$vuetify.display.width <= 1900 ? 150 : ''"
          sortable
        />
        <el-table-column
          :label="'Start Time'"
          :prop="'start_time'"
          :width="$vuetify.display.width <= 1900 ? 180 : ''"
          sortable
        />
        <el-table-column
          :label="'End Time'"
          :prop="'end_time'"
          :width="$vuetify.display.width <= 1900 ? 180 : ''"
          sortable
        />
        <el-table-column
          :label="'Usage (kWh)'"
          :prop="'usage_kWh'"
          :width="$vuetify.display.width <= 1900 ? 150 : ''"
          sortable
        />
        <el-table-column
          v-if="sessionFilter == '1'"
          :label="'Abatement (kg)'"
          :prop="'abatement_kg'"
          :width="$vuetify.display.width <= 1900 ? 150 : ''"
          sortable
        />
        <el-table-column
          v-if="sessionFilter == '2'"
          :label="'Description'"
          :prop="'description'"
          :width="$vuetify.display.width <= 1900 ? 500 : ''"
        />
      </el-table>
      <el-table
        v-else
        :data="sessionDataGroupBy"
        :fit="true"
        height="300"
        class="my-2 width-100-p data-table"
        stripe
        scrollbar-always-on
      >
        <el-table-column
          :label="
            selectedGroupBy == '2'
              ? 'Region (State)'
              : selectedGroupBy == '3'
              ? 'Charger'
              : selectedGroupBy == '4'
              ? 'Charging Site'
              : 'Description'
          "
          :prop="'group_by'"
          :width="$vuetify.display.width <= 750 ? 200 : ''"
          sortable
        />
        <el-table-column
          :label="'Number of Sessions'"
          :prop="'count_session'"
          :width="$vuetify.display.width <= 750 ? 170 : ''"
          sortable
        >
          <template #default="scope">
            {{
              scope.row.count_session == null
                ? ""
                : Number(scope.row.count_session).toLocaleString()
            }}
          </template>
        </el-table-column>
        <el-table-column
          :label="'Usage (kWh)'"
          :prop="'sum_usage'"
          :width="$vuetify.display.width <= 750 ? 200 : ''"
          sortable
        >
          <template #default="scope">
            {{
              scope.row.sum_usage == null
                ? ""
                : Number(scope.row.sum_usage).toLocaleString()
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="sessionFilter == '2'"
          :label="'Power (kW)'"
          :prop="'avg_usage'"
          :width="$vuetify.display.width <= 750 ? 200 : ''"
          sortable
        >
          <template #default="scope">
            {{
              scope.row.avg_usage == null
                ? ""
                : Number(scope.row.avg_usage).toLocaleString()
            }}
          </template>
        </el-table-column>
      </el-table>
      <el-row
        v-if="selectedGroupBy == '1'"
        class="row-bg"
        justify="space-between"
      >
        <el-col
          :span="5"
          class="d-flex justify-content-start align-items-center"
        >
          <el-select
            v-model="perPageCount"
            placeholder="Select"
            size="small"
            style="width: 70px"
          >
            <el-option label="10" :value="'10'" />
            <el-option label="25" :value="'25'" />
            <el-option label="50" :value="'50'" />
            <el-option label="100" :value="'100'" />
          </el-select>
        </el-col>
        <el-col
          :span="8"
          class="d-flex align-items-center justify-content-center"
        >
          <el-pagination
            small
            background
            layout="prev, pager, next"
            :total="total_items"
            v-model:page-size="page_size"
            v-model:current-page="current_page"
            @current-change="changePage"
            class="my-4"
          />
        </el-col>
        <el-col :span="5" class="d-flex justify-content-end align-items-center">
          <span class="text-white">
            {{ (current_page - 1) * perPageCount + 1 }}-{{
              current_page * perPageCount > total_items
                ? total_items
                : current_page * perPageCount
            }}
            of {{ total_items }}
          </span>
        </el-col>
      </el-row>
    </div>
  </el-col>
</template>

<script>
import UserService from "../../../services/user.service";
import { ElMessageBox } from "element-plus";
import { useToast } from "vue-toastification";
import XLSX from "xlsx";
// import moment from "moment";
export default {
  props: {
    filterProp: {
      type: Object,
      default: null,
    },
  },
  components: {},
  data() {
    return {
      selectedGroupBy: "1",
      sessionFilter: "1",
      sessionDataList: [],
      sessionDataGroupBy: [],
      pendingSelectionList: [],
      search: "",
      loading: false,
      current_page: 1,
      page_size: 50,
      total_items: 100,
      perPageCount: 50,
      excelDataList: [],
      total: {
        total_count: 0,
        total_usage: 0,
      },
    };
  },
  watch: {
    filterProp: {
      handler: function (newValue) {
        console.log(newValue);
        //this.fetchSessionData(this.current_page, this.search);
        this.handleRefresh();
      },
      deep: true,
    },
    selectedGroupBy: function (newVal) {
      if (newVal == 1) {
        if (this.sessionFilter == 1)
          this.fetchSessionData(this.current_page, this.search);
        else this.fetchPendingData(this.current_page, this.search);
      } else {
        this.fetchSessionGroupBy(this.search);
      }
      this.$emit("filterHandle", this.filter);
    },
    sessionFilter: function (newVal) {
      this.current_page = 1;
      this.page_size = 50;
      this.perPageCount = 50;
      this.selectedGroupBy = "1";
      if (newVal == 1) {
        this.fetchSessionData(this.current_page, this.search);
      } else if (newVal == 2) {
        this.fetchPendingData(this.current_page, this.search);
      }
    },
    current_page: function (newVal) {
      this.current_page = newVal;
      if (this.sessionFilter == 1) {
        this.fetchSessionData(this.current_page, this.search);
      } else if (this.sessionFilter == 2) {
        this.fetchPendingData(this.current_page, this.search);
      }
    },
    perPageCount: function (newVal) {
      this.current_page = 1;
      this.page_size = parseInt(newVal);
      if (this.sessionFilter == 1) {
        this.fetchSessionData(this.current_page, this.search);
      } else if (this.sessionFilter == 2) {
        this.fetchPendingData(this.current_page, this.search);
      }
    },
  },
  mounted() {
    this.fetchSessionData(this.current_page, this.search);
  },
  methods: {
    fetchSessionData(page, search) {
      let filter = {
        start_date: this.filterProp.startDate,
        end_date: this.filterProp.endDate,
        customer_id: this.filterProp.customer_id,
        charger_name: this.filterProp.charger_name,
        search: search,
        state: this.filterProp.state,
      };
      this.loading = true;
      UserService.GetSessionList(filter, page, this.perPageCount)
        .then(
          (response) => {
            this.total_items = response.data.total_record;
            this.sessionDataList = response.data.sessions;
            this.total.total_count = response.data.total_record;
            this.total.total_usage = response.data.total_usage;
            this.$emit("changeTab", this.total);
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    async fetchPendingData(page, search) {
      let filter = {
        start_date: this.filterProp.startDate,
        end_date: this.filterProp.endDate,
        search: search,
        customer_id: this.filterProp.customer_id,
        charger_name: this.filterProp.charger_name,
        state: this.filterProp.state,
      };
      await UserService.GetPendingSessionList(filter, page, this.perPageCount)
        .then(
          (response) => {
            this.sessionDataList = response.data.session_logs;
            this.total_items = response.data.total_record;
            this.total.total_count = response.data.total_record;
            this.total.total_usage = response.data.total_usage;
            this.$emit("changeTab", this.total);
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    async fetchSessionGroupBy(search) {
      let filter = {
        start_date: this.filterProp.startDate,
        end_date: this.filterProp.endDate,
        group_by: this.selectedGroupBy,
        kind: this.sessionFilter,
        customer_id: this.filterProp.customer_id,
        charger_name: this.filterProp.charger_name,
        search: search,
        state: this.filterProp.state,
      };
      this.loading = true;
      await UserService.DashboardSessionGroupBy(filter)
        .then(
          (response) => {
            console.log(response);
            this.sessionDataGroupBy =
              response.data.data.session_group_by.sessions;
            // this.total_items = response.data.total_record;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    handleRefresh() {
      this.current_page = 1;
      if (this.selectedGroupBy == "1") {
        if (this.sessionFilter == 1) {
          this.fetchSessionData(this.current_page, this.search);
        } else if (this.sessionFilter == 2) {
          this.fetchPendingData(this.current_page, this.search);
        }
      } else this.fetchSessionGroupBy(this.search);
    },
    handleSelectionChange(item) {
      this.pendingSelectionList = item;
    },
    async pendingDelete() {
      ElMessageBox.confirm(
        `Are you sure delete ${this.pendingSelectionList.length} rows ?`,
        "confirm",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "Confirm Delete",
        }
      )
        .then(async () => {
          let ids = this.pendingSelectionList.map((item) => item.id);
          let filter = {};
          filter.ids = ids;
          await UserService.sessionLogRemoveBy(filter)
            .then(
              // eslint-disable-next-line no-unused-vars
              (response) => {
                // console.log(response);
                if (ids.length >= 25) {
                  this.handleRefresh();
                } else {
                  this.sessionDataList = this.sessionDataList.filter(
                    (item) => !ids.includes(item.id)
                  );
                }
                useToast().success(
                  "The selected rows have been deleted successfully.",
                  {
                    icon: "fa fa-check",
                    position: "bottom-right",
                    timeout: 2000,
                  }
                );
              },
              (error) => {
                this.loading = false;
                console.log(error);
                if (error.response.status == 401) {
                  this.$router.push({
                    path: "/",
                  });
                }
              }
            )
            .catch((error) => {
              this.loading = false;
              console.log(error);
              if (error.response.status == 401) {
                this.$router.push({
                  path: "/",
                });
              }
            })
            .finally(() => (this.loading = false));
        })
        .catch(() => {
          this.successStep = "error";
          this.removeInvalidRow = false;
        });
    },

    async handleExportFile() {
      if (this.selectedGroupBy == "1") {
        if (this.sessionFilter == 1) {
          // await this.fetchAllSessionData(1);
          this.excelDataList = this.sessionDataList;
        } else if (this.sessionFilter == 2) {
          await this.fetchAllPendingData(1);
        }
      } else this.excelDataList = this.sessionDataGroupBy;
      if (this.excelDataList.length > 0) {
        const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
          header: Object.keys(this.excelDataList[0]),
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "SessionList.xlsx");
      }
    },

    async fetchAllSessionData(page) {
      let filter = {
        start_date: this.filterProp.startDate,
        end_date: this.filterProp.endDate,
        search: "",
        state: this.filterProp.state,
      };
      await UserService.GetSessionList(filter, page, 0)
        .then(
          (response) => {
            this.excelDataList = response.data.sessions;
          },
          (error) => {
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        });
    },

    async fetchAllPendingData(page) {
      let filter = {
        start_date: this.filterProp.startDate,
        end_date: this.filterProp.endDate,
        search: "",
        customer_id: this.filterProp.customer_id,
        charger_name: this.filterProp.charger_name,
        state: this.filterProp.stat,
      };

      await UserService.GetPendingSessionList(filter, page, 0)
        .then(
          (response) => {
            this.excelDataList = response.data.session_logs;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        });
    },
  },
};
</script>
