import {
    createRouter,
    createWebHistory
} from "vue-router"
import auth from './auth/middleware/auth';
import admin from './auth/middleware/admin';
import log from './auth/middleware/log';
import Body from './components/body';
// import Default from './pages/dashboard/default.vue';
import DashboardHome from './pages/dashboard/home/index.vue';
import DashboardSummary from './pages/dashboard/summary/index.vue';
import DashboardNemotion from './pages/dashboard/nemotionValidation/index.vue';
import DashboardCharger from './pages/dashboard/chargers/index.vue';
import DashboardSession from './pages/dashboard/sessions/index.vue';
import DashboardAbatement from './pages/dashboard/abatement/index.vue';
import DashboardTimeOfDay from './pages/dashboard/timeOfDay/index.vue';
// import dashboard/estimator 
import DashboardEstimator from './pages/dashboard/estimator/index.vue';

// import DefaultOld from './pages/dashboard/default.vue';
import Login from "./auth/login.vue";
import ForgetPass from "./auth/forgetPass.vue";
import ResetPass from "./auth/resetPass.vue";
// import Register from "./auth/register.vue";
import Charger from './pages/chargers/index.vue';
import CustomerSessions from './pages/sessions/index.vue';
import AdminSessions from './pages/adminSessions/index.vue';
import AdminSupports from './pages/adminSupports/index.vue';
import AdminSupportDetails from './pages/adminSupports/supportDetail.vue';
import Supports from './pages/supports/index.vue';
import SupportDetails from './pages/supports/supportDetail.vue';
import Profile from './auth/profile.vue';
import Users from './pages/users/index.vue';
import Customers from './pages/customers/index.vue';
import ChargingSite from './pages/adminChargers/index.vue';
import ChargingSiteDetail from './pages/adminChargers/chargingSiteDetail.vue';
import BaselineParams from './pages/baselineParams/index.vue';
import SiteBaselineParams from './pages/siteBaselineParams/index.vue';
import Participants from './pages/participants/index.vue';
import HistoryLogs from './pages/historyLogs/index.vue';
import CustomerFleet from './pages/fleet/index.vue';
import OnSiteRenewable from './pages/onSiteRenewables/index.vue';
import MonitoringPeriods from './pages/monitoringPeriods/index.vue';
import BatteryIntensities from './pages/batteryIntensities/index.vue';
import Vehicles from './pages/vehicles/index.vue';



import DashboardAuditorReports from './pages/dashboard/auditorReports/auditorReportsIndex.vue';



const routes = [{
        path: "/",
        component: Login,
        name: 'Login',
        meta: {
            middleware: log,
        },
    },
    {
        path: "/forgetPass",
        component: ForgetPass,
        name: 'forgetPass',
        meta: {
            middleware: log,
        },
    },
    {
        path: "/resetPass",
        component: ResetPass,
        name: 'resetPass',
        meta: {
            middleware: log,
        },
    },
    /*{
       path: "/register",
       name: 'Register',
       component: Register,
       meta: {
         middleware: log,
       },
     },*/

    // {
    //     path: '/dashboard-old',
    //     component: Body,
    //     name: 'DashboardOLD',
    //     children: [{
    //         path: '/dashboard-old',
    //         name: 'DashboardOLD',
    //         component: DefaultOld,
    //     }, ],
    //     meta: {
    //         middleware: [auth, log],
    //     },
    // },
    {
        path: '/dashboard',
        component: Body,
        name: 'Dashboard',
        children: [{
            path: '/dashboard',
            name: 'Dashboard',
            component: DashboardHome,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },
    {
        path: '/summary',
        component: Body,
        name: 'Dashboard-Summary',
        children: [{
            path: '/summary',
            name: 'Dashboard-Summary',
            component: DashboardSummary,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/nemotion-validation',
        component: Body,
        name: 'Dashboard-Nemotion',
        children: [{
            path: '/nemotion-validation',
            name: 'Dashboard-Nemotion',
            component: DashboardNemotion,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/dashboard-chargers',
        component: Body,
        name: 'Dashboard-Chargers',
        children: [{
            path: '/dashboard-chargers',
            name: 'Dashboard-Chargers',
            component: DashboardCharger,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },
    {
        path: '/dashboard-sessions',
        component: Body,
        name: 'Dashboard-Sessions',
        children: [{
            path: '/dashboard-sessions',
            name: 'Dashboard-Sessions',
            component: DashboardSession,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },
    {
        path: '/dashboard-abatement',
        component: Body,
        name: 'Dashboard-Abatement',
        children: [{
            path: '/dashboard-abatement',
            name: 'Dashboard-Abatement',
            component: DashboardAbatement,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },
    {
        path: '/dashboard-time-of-day',
        component: Body,
        name: 'Dashboard-TimeOfDay',
        children: [{
            path: '/dashboard-time-of-day',
            name: 'Dashboard-TimeOfDay',
            component: DashboardTimeOfDay,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },

    // path for INSIGHTS/Estimator
    {
        path: '/dashboard-estimator',
        component: Body,
        name: 'Dashboard-Estimator',
        children: [{
            path: '/dashboard-estimator',
            name: 'Dashboard-Estimator',
            component: DashboardEstimator,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },

    // path for General/Auditor Reports
    {
        path: '/dashboard-auditor-reports',
        component: Body,
        name: 'Dashboard-Auditor-Reports',
        children: [{
            path: '/dashboard-auditor-reports',
            name: 'Dashboard-Auditor-Reports',
            component: DashboardAuditorReports,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },

    {
        path: '/upload-chargers',
        component: Body,
        name: 'UploadCharger',
        children: [{
            path: '/upload-chargers',
            name: 'UploadCharger',
            component: Charger,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },
    {
        path: '/charging-sites',
        component: Body,
        name: 'ChargingSite',
        children: [{
            path: '/charging-sites',
            name: 'ChargingSite',
            component: ChargingSite,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/charging-sites-detail',
        component: Body,
        name: 'ChargingSiteDetail',
        children: [{
            path: '/charging-sites-detail',
            name: 'ChargingSiteDetail',
            component: ChargingSiteDetail,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/upload-sessions',
        component: Body,
        name: 'UploadSessions',
        children: [{
            path: '/upload-sessions',
            name: 'UploadSessions',
            component: CustomerSessions,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },
    {
        path: '/sessions',
        component: Body,
        name: 'Sessions',
        children: [{
            path: '/sessions',
            name: 'Sessions',
            component: AdminSessions,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/tickets',
        component: Body,
        name: 'Tickets',
        children: [{
            path: '/tickets',
            name: 'Tickets',
            component: Supports,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },
    {
        path: '/ticket_details',
        component: Body,
        children: [{
            path: '',
            name: 'TicketDetails',
            component: SupportDetails,
            // props: true,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/supports',
        component: Body,
        name: 'Supports',
        children: [{
            path: '/supports',
            name: 'Supports',
            component: AdminSupports,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/support_details',
        component: Body,
        children: [{
            path: '',
            name: 'SupportDetails',
            component: AdminSupportDetails,
            // props: true,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },
    {
        path: '/account',
        component: Body,
        name: 'Account',
        children: [{
            path: '/account',
            name: 'Account',
            component: Profile,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },
    {
        path: '/users',
        component: Body,
        name: 'Users',
        children: [{
            path: '/users',
            name: 'Users',
            component: Users,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/customers',
        component: Body,
        name: 'Customers',
        children: [{
            path: '/customers',
            name: 'Customers',
            component: Customers,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/baseline-params',
        component: Body,
        name: 'BaselineParams',
        children: [{
            path: '/baseline-params',
            name: 'BaselineParams',
            component: BaselineParams,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/site-baseline-params',
        component: Body,
        name: 'SiteBaselineParams',
        children: [{
            path: '/site-baseline-params',
            name: 'SiteBaselineParams',
            component: SiteBaselineParams,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/participants',
        component: Body,
        name: 'Participants',
        children: [{
            path: '/participants',
            name: 'Participants',
            component: Participants,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/history-logs',
        component: Body,
        name: 'HistoryLogs',
        children: [{
            path: '/history-logs',
            name: 'HistoryLogs',
            component: HistoryLogs,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/upload-fleet',
        component: Body,
        name: 'UploadFleet',
        children: [{
            path: '/upload-fleet',
            name: 'UploadFleet',
            component: CustomerFleet,
        }, ],
        meta: {
            middleware: [auth, log],
        },
    },
    {
        path: '/on-site-renewable',
        component: Body,
        name: 'OnSiteRenewable',
        children: [{
            path: '/on-site-renewable',
            name: 'OnSiteRenewable',
            component: OnSiteRenewable,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/monitoring-periods',
        component: Body,
        name: 'MonitoringPeriods',
        children: [{
            path: '/monitoring-periods',
            name: 'MonitoringPeriods',
            component: MonitoringPeriods,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/battery-intensities',
        component: Body,
        name: 'BatteryIntensities',
        children: [{
            path: '/battery-intensities',
            name: 'BatteryIntensities',
            component: BatteryIntensities,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
    {
        path: '/vehicles',
        component: Body,
        name: 'Vehicles',
        children: [{
            path: '/vehicles',
            name: 'Vehicles',
            component: Vehicles,
        }, ],
        meta: {
            middleware: [auth, admin, log],
        },
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

function nextFactory(context, middleware, index) {
    // console.log("nextFactory")
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;
    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({
            ...context,
            next: nextMiddleware
        });
    };
}

router.beforeEach((to, from, next) => {
    // console.log("beforeEach")
    // console.log("Admin Auth", this.$store.state.auth.user.is_admin)
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ?
            to.meta.middleware : [to.meta.middleware];
        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({
            ...context,
            next: nextMiddleware
        });
    }
    return next();
});

export default router